import React from "react";
import { Link } from "react-router-dom";

import Headroom from "headroom.js";
import TopBar from "components/TopBar";
import {
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  NavItem,
  NavLink,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import "views/IndexSections/Sections.css";
import "assets/css/style.css";

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  render() {
    return (
      <>
        <TopBar />
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <div className="nav-cont">
              {/* <Container> */}
              {/* college logo */}
              <NavbarBrand className="mr-lg-5 d-flex" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/brand/logo.png")}
                  className="logo-nav"
                />
                <div className="navbar-brand-head-kota">
                  <h6>भारतीय सूचना प्रौद्योगिकी संस्थान कोटा</h6>
                  <h6>Indian Institute of Information Technology Kota</h6>
                  <h6>
                    (An Institute of National Importance under an Act of
                    Parliament)
                  </h6>
                </div>
              </NavbarBrand>
              {/* college logo end */}

              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>

              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                {/* mobile menu logo */}
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-close" xs="12">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                {/* mobile menu logo end */}
                <div className="menu-options">
                  <Nav
                    className="navbar-nav-hover align-items-lg-center"
                    navbar
                  >
                    <UncontrolledDropdown nav>
                      <DropdownToggle nav>
                        <i className="fas fa-address-card d-lg-none mr-1" />
                        <span className="nav-link-inner--text ml-lg--2">
                          ABOUT US
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem to="/about-institute" tag={Link}>
                          About Institute
                        </DropdownItem>

                        <DropdownItem to="/partnership" tag={Link}>
                          Partnership
                        </DropdownItem>
                        <DropdownItem to="/annual-reports" tag={Link}>
                          Annual Reports
                        </DropdownItem>
                        <DropdownItem to="/statues" tag={Link}>
                          Statutes and PPP Act
                        </DropdownItem>
                        {/* <DropdownItem to="/webmasters" tag={Link}>
                        Webmasters
                      </DropdownItem> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown nav>
                      <DropdownToggle nav>
                        <i className="fas fa-building d-lg-none mr-1" />
                        <span className="nav-link-inner--text ml-lg--2">
                          ADMINISTRATION
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem to="/visitor" tag={Link}>
                          Visitor
                        </DropdownItem>
                        <DropdownItem to="/board-of-governors" tag={Link}>
                          Board of Governors
                        </DropdownItem>
                        <DropdownItem to="/finance-committee" tag={Link}>
                          Finance Commitee
                        </DropdownItem>
                        <DropdownItem to="/chairperson" tag={Link}>
                          Chairperson
                        </DropdownItem>
                        <DropdownItem to="/director" tag={Link}>
                          Director
                        </DropdownItem>
                        <DropdownItem to="/Registrar" tag={Link}>
                          Registrar
                        </DropdownItem>
                        <DropdownItem to="/coordinator" tag={Link}>
                          Coordinator
                        </DropdownItem>
                        <DropdownItem to="/deans" tag={Link}>
                          Deans
                        </DropdownItem>
                        {/* <DropdownItem to="/administration" tag={Link}>
                          Administration
                        </DropdownItem> */}
                        <DropdownItem to="/senate" tag={Link}>
                          Senate
                        </DropdownItem>
                        <DropdownItem to="/executive-committee" tag={Link}>
                          Executive Committee
                        </DropdownItem>
                        <DropdownItem to="/building-work-committee" tag={Link}>
                          Building And Works Committee
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                      <DropdownToggle nav>
                        <i className="fas fa-graduation-cap d-lg-none mr-1" />
                        <span className="nav-link-inner--text ml-lg--2">
                          ADMISSION
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem to="/ug-procedure" tag={Link}>
                          UG Admissions
                        </DropdownItem>
                        <DropdownItem to="/pg-procedure" tag={Link}>
                          PG Admissions
                        </DropdownItem>
                        <DropdownItem to="/fees" tag={Link}>
                          Fees
                        </DropdownItem>

                        <DropdownItem to="/scholarship" tag={Link}>
                          Scholarship
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                      <DropdownToggle nav>
                        <i className="fas fa-user d-lg-none mr-1" />
                        <span className="nav-link-inner--text ml-lg--2">
                          PEOPLE
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {/* <DropdownItem to="/alumni" tag={Link}>
                        Alumni
                      </DropdownItem> */}
                        <DropdownItem to="/faculty" tag={Link}>
                          Faculty
                        </DropdownItem>
                        <DropdownItem to="/office-staff" tag={Link}>
                          Office Staff
                        </DropdownItem>
                        <DropdownItem to="/committees" tag={Link}>
                          Committees
                        </DropdownItem>
                        {/* <DropdownItem to="/students" tag={Link}>
                          Students
                        </DropdownItem> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                      <DropdownToggle nav>
                        <i className="fas fa-book d-lg-none mr-1" />
                        <span className="nav-link-inner--text ml-lg--2">
                          ACADEMICS
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem to="/convocation" tag={Link}>
                          Convocation
                        </DropdownItem>
                        <DropdownItem to="/department" tag={Link}>
                          Departments
                        </DropdownItem>
                        <DropdownItem to="/calender" tag={Link}>
                          Calendar
                        </DropdownItem>
                        {/* <DropdownItem to="/courses" tag={Link}>
                        Courses
                      </DropdownItem> */}
                        {/* d */}
                        <DropdownItem to="/curriculum" tag={Link}>
                          Curriculum
                        </DropdownItem>

                        <DropdownItem to="/time-table" tag={Link}>
                          Time Table
                        </DropdownItem>
                        <DropdownItem to="/examination-evaluation" tag={Link}>
                          Examination and Evaluation
                        </DropdownItem>
                        <DropdownItem to="/ordinance" tag={Link}>
                          Ordinance
                        </DropdownItem>
                        {/* <DropdownItem to="/undergraduate-program" tag={Link}>
                          Undergraduate Program
                        </DropdownItem>
                        <DropdownItem to="/postgraduate-programmes" tag={Link}>
                          Postgraduate Programmes
                        </DropdownItem> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                      <NavLink href="https://tpcell.iiitkota.ac.in">
                        <span className="nav-link-inner--text ml-lg--2">
                          PLACEMENTS
                        </span>
                      </NavLink>
                    </NavItem>
                    {/* <UncontrolledDropdown>
                  
                    <a
                      href="https://tpcell.iiitkota.ac.in"
                      style={{ color: "white" }}
                    >
                      <DropdownToggle nav>
                        <i className="fas fa-chart-line d-lg-none mr-1" />
                        <span className="nav-link-inner--text">PLACEMENTS</span>
                      </DropdownToggle>{" "}
                    </a>
                    <DropdownMenu>
                      <DropdownItem to="/why-iiitkota" tag={Link}>
                        Why IIIT Kota?
                      </DropdownItem>
                      <DropdownItem to="/our-recruiters" tag={Link}>
                        Our Recruiters
                      </DropdownItem>
                      <DropdownItem to="/placement-statistics" tag={Link}>
                        Placement Statistics
                      </DropdownItem>
                      <DropdownItem to="/traning-placement-cell" tag={Link}>
                        Training &amp; Placement Cell
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                 */}
                    <UncontrolledDropdown nav>
                      <DropdownToggle nav>
                        <i className="fas fa-hands d-lg-none mr-1" />
                        <span className="nav-link-inner--text ml-lg--2 mr-lg-6">
                          FACILITIES
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="ScrollableMenu" id="facilities">
                        <DropdownItem to="/library" tag={Link}>
                          Library
                        </DropdownItem>
                        {/* <DropdownItem to="/achievements" tag={Link}>
                          Achievements
                        </DropdownItem> */}
                        <DropdownItem to="/canteen" tag={Link}>
                          Canteen
                        </DropdownItem>
                        <DropdownItem to="/clubs" tag={Link}>
                          Clubs
                        </DropdownItem>
                        <DropdownItem to="/computer-labs" tag={Link}>
                          Computer Labs
                        </DropdownItem>
                        {/* <DropdownItem to="/events" tag={Link}>
                          Events
                        </DropdownItem> */}
                        <DropdownItem to="/fests" tag={Link}>
                          Fests
                        </DropdownItem>
                        {/* <DropdownItem to="/gallery" tag={Link}>
                          Gallery
                        </DropdownItem> */}
                        <DropdownItem to="/hostels" tag={Link}>
                          Hostels
                        </DropdownItem>

                        {/* <DropdownItem to="/medical" tag={Link}>
                          Medical
                        </DropdownItem> */}
                        {/* <DropdownItem to="/rules-desciplines" tag={Link}>
                          Rules &amp; Disciplines
                        </DropdownItem> */}
                        {/* <DropdownItem to="/bank" tag={Link}>
                          Bank
                        </DropdownItem>
                        <DropdownItem to="/postoffice" tag={Link}>
                          Post Office
                        </DropdownItem> */}

                        <DropdownItem to="/sports" tag={Link}>
                          Sports
                        </DropdownItem>
                        <DropdownItem to="/trai-lab" tag={Link}>
                          TRAI Lab
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                </div>
              </UncontrolledCollapse>
              {/* </Container> */}
            </div>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
