import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Strip.css";
import library from "./img/library.png";
import placement from "./img/placement.png";
import scholarship from "./img/scholarship.png";
import clubs from "./img/clubs.png";
import admission from "./img/admission.png";
import curriculum from "./img/curriculum.png";
import calendar from "./img/cal.png";

const Strip = () => {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);

  return (
    <>
      <div className="strip">
        <div className="inside-strip">
          <div className="strip-head">
            <h1 className="display-3" style={{ color: "#fff" }}>
              Student Corner
            </h1>
          </div>
          <br />
          <div className="strip-body">
            <div className="bod-1">
              <Link to="/procedure">
                <div
                  className={isHovered5 ? "box1-strip2" : "box1-strip"}
                  onMouseEnter={() => {
                    setIsHovered5(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered5(false);
                  }}
                >
                  <img src={admission} alt="" className="strip-img"></img>
                  <h3 className="box-strip-tagline1">Admission</h3>
                </div>
              </Link>
              <Link to="/library">
                <div
                  className={isHovered1 ? "box1-strip2" : "box1-strip"}
                  onMouseEnter={() => {
                    setIsHovered1(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered1(false);
                  }}
                >
                  <img src={library} alt="" className="strip-img"></img>
                  <h3 className="box-strip-tagline1">Library</h3>
                </div>
              </Link>
              </div>
              <div className="bod-1">
              <Link to="/scholarship">
                <div
                  className={isHovered3 ? "box1-strip2" : "box1-strip"}
                  onMouseEnter={() => {
                    setIsHovered3(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered3(false);
                  }}
                >
                  <img src={scholarship} alt="" className="strip-img"></img>
                  <h3 className="box-strip-tagline1">Scholarship</h3>
                </div>
              </Link>
              <Link to="/curriculum">
                <div
                  className={isHovered2 ? "box1-strip2" : "box1-strip"}
                  onMouseEnter={() => {
                    setIsHovered2(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered2(false);
                  }}
                >
                  <img src={curriculum} alt="" className="strip-img"></img>
                  <h3 className="box-strip-tagline1">Curriculum</h3>
                </div>
              </Link>
              </div>
              <div className="bod-1">
              <Link to="/calender">
                <div
                  className={isHovered6 ? "box1-strip2" : "box1-strip"}
                  onMouseEnter={() => {
                    setIsHovered6(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered6(false);
                  }}
                >
                  <img src={calendar} alt="" className="strip-img"></img>
                  <h3 className="box-strip-tagline1">Calendar</h3>
                </div>
              </Link>
              <Link to="/clubs">
                <div
                  className={isHovered4 ? "box1-strip2" : "box1-strip"}
                  onMouseEnter={() => {
                    setIsHovered4(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered4(false);
                  }}
                >
                  <img src={clubs} alt="" className="strip-img"></img>
                  <h3 className="box-strip-tagline1">Clubs</h3>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Strip;
