import React from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";

class TenderInvitations extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      readMore: false,
    };
  }

  handleReadmore = () => {
    this.setState((prevState) => ({
      readMore: !prevState.readMore,
    }));
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Tender Invitations - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Tender Invitations for Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="IIIT Kota Tender Invitions,  IIIT Kota,  Indian Institute of Information Technology Kota Tender Invitations, Tender Invitions, Top IIITs, MNIT Jaipur Tender Invitations, NIT Jaipir "
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            
              <Row className="justify-content-center text-center mb-lg-5 ">
                <Col lg="8">
                  <h2>
                    <b>Tender Invitations</b>
                  </h2>
                </Col>
              </Row>


              <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                          

                          <h6>
                            <b>Tender Name:</b> Limited Tender Inquiry for Providing Group Medical Insurance for IIIT Kota Students
                            <h6>
                            <br/>
                            Tender No:
                            </h6>
                          </h6>
                         
                          <br/>

                          <h6>
                          <b>Bid Opening Date:</b>18 July, 2024
                          </h6>
                          <h6>
                          <b>Bid Closing Date:</b>07 Aug, 2024
                          </h6>
                          <br />
                         <a href="https://files.iiitkota.ac.in/pdf/tender/Tender_Documents.pdf" target="_blank" className="fas"><h6><u>Download Link</u></h6></a>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                </Row>



              <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                          

                          <h6>
                            <b>Tender Name:</b> A Pair of Horn Antenna for each given bands (L, S, C, X and Ku band)                          
                            <h6>
                            <br/>
                            Tender No:  GEM/2024/B/4965775
                            </h6>
                          </h6>
                          <br/>
                          <h6>
                          <b>Bid Opening Date:</b>21 May, 2024
                          </h6>
                          <h6>
                          <b>Bid Closing Date:</b>12 June, 2024
                          </h6>
                          <br />
                          <a href="https://files.iiitkota.ac.in/pdf/tender/GeM-Bidding-6426744.pdf" target="_blank" className="fas"><h6><u>Download Link</u></h6></a>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                </Row>


              <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                      

                          <h6>
                            <b>Tender Name:</b> Fiber Optic Connectorization and Splicing Tool Kit
                            
                            <h6>
                            <br/>
                            Tender No:  GEM/2024/B/4963989
                            </h6>
                          </h6>
                          <br/>
                          <h6>
                          <b>Bid Opening Date:</b>21 May, 2024
                          </h6>
                          <h6>
                          <b>Bid Closing Date:</b>12 June, 2024
                          </h6>
                          <br />
                          <a href="https://files.iiitkota.ac.in/pdf/tender/GeM-Bidding-6424660.pdf" target="_blank" className="fas"><h6><u>Download Link</u></h6></a>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                </Row>


              <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                         

                          <h6>
                            <b>Tender Name:</b> Canteen Service - Best Price On Fixed Menu Rate Model - Vegetarian, Non-Vegetarian;. Breakfast, Lunch, Dinner, Snacks
                            <h6>
                            <br/>
                            Tender No: GEM/2024/B/4964196
                            </h6>
                          </h6>
                          <br/>
                          <h6>
                          <b>Bid Opening Date:</b>21st May, 2024 
                          </h6>
                          <h6>
                          <b>Bid Closing Date:</b>12th June, 2024
                          </h6>
                          <br />
                          <a href="https://files.iiitkota.ac.in/pdf/tender/GeM-Bidding-6424895 (2).pdf" target="_blank" className="fas"><h6><u>Download Link</u></h6></a>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                </Row>


                <div style={{ display: "flex", justifyContent: "center"}} className="mb-5">
                      <div
                        onClick={this.handleReadmore}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 4,
                          border: "solid 1px black",
                          padding: "3px 7px",
                          cursor: "pointer",
                          backgroundColor: "#0E407C",
                          color: "#ffffff",
                        }}
                      >
                       
                       {this.state.readMore?
                        
                        "Close Archive"
                        :"View Archive"
                       }
                      
                        
                      </div>
                    </div>

              
              
                    {this.state.readMore ?
              <>
              <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                          <center>
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 ">
                              <a href="https://files.iiitkota.ac.in/pdf/tender/GeM-Bidding-6205042.pdf">
                                <i className="fas fa-external-link-alt"></i>
                              </a>
                            </div>
                          </center>

                          <h6>
                            GEM Tender for Vivado ML Enterprise Edition and Vitis Model composer,University License
                          </h6>
                          <br/>
                          <h6>
                          <b>Bid Opening Date:</b>11th March, 2024 
                          </h6>
                          <h6>
                          <b>Bid Closing Date:</b>2nd April, 2024
                          </h6>
                          <br />
                          <h6>
                            <b>Tender Name:</b> Vivado ML Enterprise Edition and Vitis Model composer,University License
                            <h6>
                            Tender No: GEM/2024/B/4764901
                            </h6>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                </Row>

              <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                          <center>
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 ">
                              <a href="https://files.iiitkota.ac.in/pdf/tender/GEM-2024-B-4545309.pdf">
                                <i className="fas fa-external-link-alt"></i>
                              </a>
                            </div>
                          </center>

                          <h6>
                            GEM Tender for NI Academic Volume License LabVIEW Option
                          </h6>
                          <br/>
                          <h6>
                          <b>Bid Opening Date:</b>29th January, 2024 
                          </h6>
                          <h6>
                          <b>Bid Closing Date:</b>20th February, 2024
                          </h6>
                          <br />
                          <h6>
                            <b>Tender Name:</b> NI Academic Volume License LabVIEW
                            Tender No: GEM/2024/B/4545309
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                </Row>

                <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                          <center>
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 ">
                              <a href="https://files.iiitkota.ac.in/pdf/tender/GEM-2024-B-4545104.pdf">
                                <i className="fas fa-external-link-alt"></i>
                              </a>
                            </div>
                          </center>

                          <h6>
                            GEM Tender for NI Academic Volume License Multisim Option
                          </h6>
                          <br/>
                          <h6>
                          <b>Bid Opening Date:</b>29th January, 2024 
                          </h6>
                          <h6>
                          <b>Bid Closing Date:</b>20th February, 2024
                          </h6>
                          <br />
                          <h6>
                            <b>Tender Name:</b> NI Academic Volume License Multisim 
                            Tender No: GEM/2024/B/4545104
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                </Row>

              <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                          <center>
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 ">
                              <a href="https://files.iiitkota.ac.in/pdf/tender/GeM-Bidding-5595986.pdf">
                                <i className="fas fa-external-link-alt"></i>
                              </a>
                            </div>
                          </center>

                          <h6>
                            GEM Tender Document for Procurement of CST Studio Suite
                          </h6>
                          <br/>
                          <h6>
                          <b>Bid Opening Date:</b>14th November ,2023
                          </h6>
                          <h6>
                          <b>Bid Closing Date:</b>6th December ,2023
                          </h6>
                          <br />
                          <h6>
                            <b>Tender Name:</b> CST Studio Suite
                            Tender No: GEM/2023/B/4208521
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                </Row>

                <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                          <center>
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 ">
                              <a href="https://files.iiitkota.ac.in/pdf/tender/GeM-Bidding-5594457.pdf">
                                <i className="fas fa-external-link-alt"></i>
                              </a>
                            </div>
                          </center>
                          <h6>
                            GEM Tender Document for Procurement of Proteus Simulation Software
                          </h6>
                          <br/>
                          <h6>
                          <b>Bid Opening Date:</b>14th November ,2023
                          </h6>
                          <h6>
                          <b>Bid Closing Date:</b>6th December ,2023
                          </h6>
                          <br />
                          <h6>
                            <b>Tender Name:</b> Proteus Simulation Software
                            Tender No: GEM/2023/B/4207159
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                </Row>

                <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                          <center>
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 ">
                              <a href="https://files.iiitkota.ac.in/pdf/tender/GeM-Bidding-5594686.pdf">
                                <i className="fas fa-external-link-alt"></i>
                              </a>
                            </div>
                          </center>

                          <h6>
                            GEM Tender Document for Procurement of Optisystem Simulation tool Software
                          </h6>
                          <br/>
                          <h6>
                          <b>Bid Opening Date:</b>14th November ,2023 
                          </h6>
                          <h6>
                          <b>Bid Closing Date:</b>6th December ,2023
                          </h6>
                          <br />
                          <h6>
                            <b>Tender Name:</b> Optisystem Simulation tool Software
                            Tender No: GEM/2023/B/4207358
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                </Row>

                <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                          <center>
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 ">
                              <a href="https://files.iiitkota.ac.in/pdf/tender/GeM-Bidding-5594975.pdf">
                                <i className="fas fa-external-link-alt"></i>
                              </a>
                            </div>
                          </center>

                          <h6>
                            GEM Tender Document for Procurement of OptSim Base Software
                          </h6>
                          <br/>
                          <h6>
                          <b>Bid Opening Date:</b>14th November ,2023
                          </h6>
                          <h6>
                          <b>Bid Closing Date:</b>6th December ,2023
                          </h6>
                          <br />
                          <h6>
                            <b>Tender Name:</b> OptSim Base Software
                            Tender No: GEM/2023/B/4207618
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                </Row>

                <Row className="justify-content-center mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-justify ">
                          <center>
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 ">
                              <a href="https://files.iiitkota.ac.in/pdf/tender/Library_Tender.pdf">
                                <i className="fas fa-external-link-alt"></i>
                              </a>
                            </div>
                          </center>

                          <h6>
                            The Limited Tender Document along with list of books
                            for purchase of Books for Library IIIT Kota Tender
                            with List of books.
                          </h6>
                          <br />
                          <h6>
                            <b>Tender Name:</b> Limited Tender Inquiry for
                            Supply & Installation of Furniture for Classroom
                            Tender No: IIITK/11/2017-18
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
                </>
              :""}

          </section>
        </main>

        <NewFooter />
      </>
    );
  }
}

export default TenderInvitations;
