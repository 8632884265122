import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Ai_de extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Department - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Department Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Department, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Deparment in IIIT Kota, CSE Deparment,ECE Department , Electronics and Communication Engineering Department,Computer Science and Engineering Department,IIIT Kota Department,CSE Programme Advisors,ECE Programme Advisors"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg ">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Departments</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12}>
                  <h4>
                    <b>Artificial Intelligence and Data Engineering</b>
                  </h4>
                  <h6 className="pb-3">
                    Department of Artificial Intelligence and Data Engineering started
                    functioning since 2024-25. Currently, the department has a total intake of 60 seats and
                    offers a four-year B.Tech programme.The department is in the process to get
                    state-of-the-art labs to support the undergraduate program
                    better.
                  </h6>
                </Col>
                <Col lg={12} xs={12}>
                  <h5>
                    <b>Offered Programs</b>
                  </h5>
                  <h6>
                    <ul>
                      <li>
                        B.Tech. in Artificial Intelligence and Data Engineering (AI&DE)
                      </li>
                    </ul>
                  </h6>

                  <h5>
                    <b>Head of Department</b>
                  </h5>
                  <h6>
                    <ul>
                      <li>
                        <a href="/faculty/608540887fb8f4001564e1b2">Dr. Isha Pathak Tripathi</a>
                      </li>
                    </ul>
                  </h6>

                </Col>
              </Row>
              
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Ai_de;