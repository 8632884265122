import React from "react";
import CardMedia from "@material-ui/core/CardMedia";
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Clubs extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Clubs - Indian Institute of Information Technology Kota</title>
          <meta
            name="description"
            content="Clubs Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Clubs, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Clubs IIIT Kota,IIIT Kota Clubs,Algorithmus,Incognito,Odyssey,Arc Robotics,IIIT kota Codebase,Capture,Developer Student Club,Fit India Club,Qns IIIT Kota,Coding Club IIIT Kota,Dance Club IIIT Kota,Literature Club IIIT Kota, Magazine IIIT Kota,Photography Club IIIT Kota,Open Source IIIT Kota,Music Club IIIT Kota,Robotics Club IIIT Kota"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Clubs and Forums</b>
                  </h2>
                </Col>
              </Row>
              <Row className="justify-content-center text mb-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/algorithmus.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://algorithmus.vercel.app"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Algorithmus </b>
                            </a>
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/algorithmus.iiitkota"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="mailto:algorithmus.iiitkota@gmail.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="https://github.com/Algorithmus-IIITK"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-github" />
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* */}
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/arc.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://arc-robotics.vercel.app/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>ARC Robotics</b>
                              <br />
                            </a>
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/arc_robotics_/"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="mailto:arc.robotics.iiitkota@gmail.com"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="https://www.linkedin.com/company/arc-robotics-iiitk"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-linkedin-in" />
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/artive.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://artiveiiitkota.wixsite.com/artive-iiit-kota"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Artive Club</b>
                            </a>
                            <br />
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/artive_iiitkota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            {/* <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="#click"
                              // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="#click"
                              // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-facebook" />
                            </Button> */}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center text mb-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    {/* <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/Capture.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://www.instagram.com/capture_iiitkota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Capture Club </b>
                            </a>
                            <br />
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/capture_iiitkota/"
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> */}
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt="codebase"
                            width="100%"
                            image={require("assets/img/clubs/codebase.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://ikcb.org"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>CodeBase </b>
                            </a>
                            <br />
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/iiitkota_codebase/"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="mailto:codebase@iiitkota.ac.in"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="https://github.com/ikcb"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-github" />
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/cypher_wb.jpg")}
                            title=""
                          />

                          <h6 style={{ marginTop: "15px" }} className="text-primary text-uppercase text-center">
                            <a
                              href="https://sites.google.com/iiitkota.ac.in/cyph3r/home"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>CYPH3R</b>
                              <br />
                            </a>
                          </h6>

                          <div className="text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/cyph3r.iiitkota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            {/* <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="mailto:arc.robotics.iiitkota@gmail.com"
                              // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button> */}
                            {/* <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="https://www.linkedin.com/company/arc-robotics-iiitk"
                              target="_blank"
                              rel="noopener noreferrer"
                              // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-linkedin-in" />
                            </Button> */}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/dsc.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://dsc.community.dev/indian-institute-of-information-technology-kota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Developer Student Clubs </b>
                            </a>
                            <br />
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/gdsciiitkota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            {/* <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="#click"
                              target="_blank"
                              rel="noopener noreferrer"
                              // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button> */}
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="https://www.linkedin.com/company/developer-student-clubs-dsc-iiit-kota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-linkedin-in" />
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center text mb-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">

                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/fitindia.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://fitindiaiiitkota.vercel.app/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Fit India Club </b>
                            </a>
                            <br />
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/fitindia_iiitkota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="mailto:fitindiaclub@iiitkota.ac.in"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://twitter.com/fitindia_iiitk"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-twitter"></i>
                            </Button>
                            {/* <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="#click"
                              // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-facebook" />
                            </Button> */}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/incognito.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://incognito-incognito-iiitkota.vercel.app/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Incognito Club </b>
                            </a>
                            <br />
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/incognito_iiitkota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="mailto:incognito@iiitkota.ac.in"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button>
                            {/* <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="#click"
                              // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-facebook" />
                            </Button> */}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/kernel.jpg")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://iiitkota.ac.in"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>IIIT Kernel</b>
                            </a>
                            <br />
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/kernel.iiitkota"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.linkedin.com/company/iiit-kernel"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-linkedin-in"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="kernel@iiitkota.ac.in"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center text mb-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/neon.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://neoncinematics.herokuapp.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>NEON Cinematics Club </b>
                            </a>
                            <br />
                          </h6>
                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://instagram.com/neoncinematics?utm_medium=copy_link"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            {/* <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://twitter.com/IiitkEbsb"
                              target="_blank"
                              rel="noopener noreferrer"
                              // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-twitter"></i>
                            </Button> */}
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="mailto:neoncinematics@iiitkota.ac.in"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://instagram.com/neoncinematics?utm_medium=copy_link"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/odyssey.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://odyssey-iiitkota.netlify.app/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Odyssey Club </b>
                            </a>
                            <br />
                          </h6>
                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/odyssey_iiitkota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            {/* <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="#click"
                              // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="#click"
                              // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-facebook" />
                            </Button> */}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/qns.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://sites.google.com/view/qnsiiitkota/home"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Q'n'S Institute Magazine </b>
                            </a>
                            <br />
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://www.instagram.com/qns_iiitkota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="mailto:magazine.iiitkota@gmail.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-envelope"></i>
                            </Button>
                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="https://www.facebook.com/qnsiiitkota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-facebook" />
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center text mb-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    {/* <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/ebsb.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://twitter.com/IiitkEbsb"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>EBSB Club </b>
                            </a>
                            <br />
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://twitter.com/IiitkEbsb"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-twitter"></i>
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> */}

                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <CardMedia
                            component="img"
                            alt=""
                            width="100%"
                            image={require("assets/img/clubs/tgccLogo.png")}
                            title=""
                          />

                          <h6 className="text-primary text-uppercase text-center">
                            <a
                              href="https://teamgreen.org.in/campus-chapter-iiit-kota/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Team Green Campus Chapter </b>
                            </a>
                            <br />
                          </h6>

                          <div className="mt-3 text-center">
                            <Button
                              className="btn-icon-only rounded-circle theme-color-kota-bg"
                              href="https://instagram.com/tgcc_iiitkota"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>

                            <Button
                              className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                              href="https://www.linkedin.com/company/team-green-campus-chapter-iiit-kota"
                              target="_blank"
                              rel="noopener noreferrer"
                            // onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-linkedin-in" />
                            </Button>

                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Clubs;