import React from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "bootstrap";
import "assets/css/style.css";
import Helmet from "react-helmet";

class Iccw extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>ICCW - Indian Institute of Information Technology Kota</title>
          <meta
            name="description"
            content="Internal Complaint Commitee - WOMEN IIIT Kota"
          />
          <meta
            name="keywords"
            content="ICCW IIIT Kota,Internal Complaint Commitee - WOMEN IIIT Kota, Internal Complaint Commitee - MNIT Jaipur, Top IIITs, Women Complaint Commitee, Commitee IIIT Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2 className="display-3">ICCW</h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="mb-5 ">
                <Col lg={12} xs={12}>
                  <h4>
                    <b>Internal Complaint Commitee</b> - WOMEN
                  </h4>
                </Col>
                <Col lg={12} xs={12}>
                  <h6>
                    The women’s cell of IIIT Kota was established in accordance
                    with the directives of Honorable Supreme Court of India to
                    ensure safety, security and dignity of women at workplace.
                    It's objective is to create a congenial working environment
                    which is free of sexual harassment or gender based
                    discrimination for all women employees and students. To
                    heighten awareness and sensitivity to this important issue
                    amongst all concerned.
                  </h6>
                </Col>
              </Row>
              <Row className="mb-5 ">
                <Col lg={12} xs={12}>
                  <h4>
                    <b>Sexual Harassement</b> DEFINED
                  </h4>
                </Col>
                <Col lg={12} xs={12}>
                  <h6>
                    According to Supreme Court Judgment on Sexual Harassment, on
                    the case of Vishakha vs. the State of Rajasthan (August
                    1977), the behavior that can be considered as sexual
                    harassment has been explicitly, legally defined as
                    following- Sexual Harassment includes:
                    <ol className="mt-3">
                      <li>Physical contact and advances.</li>
                      <li>Demand or request for sexual favors.</li>
                      <li>Sexually coloured remarks.</li>
                      <li>Display of pornography.</li>
                      <li>
                        Any other unwelcome physical, verbal or nonverbal
                        conduct of a sexual nature.
                      </li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={12} xs={12}>
                  <h6>
                    The critical factor in sexual harassment is the
                    unwelcomeness of the behavior. Thus, it is the impact of
                    behavior on the recipient, rather than the intent of the
                    perpetrator which is to be considered.
                  </h6>
                </Col>
              </Row>
              <Row className="mb-5 ">
                <Col lg={12} xs={12} className="text-center">
                  <h4>
                    <b>PRESIDING</b> OFFICER
                  </h4>
                </Col>
              </Row>
              <Row className="justify-content-center text mb-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 text-primary">
                            <i className="fas fa-address-book" />
                          </div>

                          <h6 className="text-primary text-uppercase">
                            <b>Dr. Namita Mittal</b>
                            <br />
                          </h6>

                          <div className="px-2 pb-2">
                            Associate Professor
                            <br />
                            CSE, MNIT Jaipur
                            <br />
                            IIIT Kota
                          </div>

                          <h6>
                            <a href="mailto:nmittal.cse@mnit.ac.in">
                              <i className="fa fa-envelope px-2" />
                              nmittal.cse@mnit.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:0141-2713324 ">
                              <i className="fas fa-phone-square-alt px-2" />
                              0141-2713324
                            </a>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center text mb-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <a href="https://files.iiitkota.ac.in/pdf/iccw/office_memorandum.pdf">
                              <i className="fa fa-file-pdf-o"></i>
                            </a>
                          </div>

                          <h6 className="text-primary text-uppercase">
                            <a href="https://files.iiitkota.ac.in/pdf/iccw/office_memorandum.pdf">
                              <b>Official Memorandum</b>
                            </a>{" "}
                            <br />
                            <br />
                            <br />
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <a href="https://files.iiitkota.ac.in/pdf/iccw/Handbook_Sexual_Harassment_women_Workplace.pdf">
                              <i className="fa fa-file-pdf-o"></i>
                            </a>
                          </div>

                          <h6 className="text-primary text-uppercase">
                            <a href="https://files.iiitkota.ac.in/pdf/iccw/Handbook_Sexual_Harassment_women_Workplace.pdf">
                              <b>
                                Handbook on Sexual Harassment of Women at
                                Workplace
                              </b>
                            </a>
                            <br />
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-5 ">
                <Col lg={12} xs={12} className="text-center">
                  <h4>
                    <b>Constitution of </b> Women's Cell
                  </h4>
                </Col>
              </Row>
            </Container>
            <Container lg={12} xs={12} className="kota-table">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Constitution</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Email</th>
                    <th scope="col">Responsibility</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Dr. Namita Mittal</th>
                    <td>
                      A Presiding Officer who shall be a woman employed at
                      senior level at workplace amongst the employ ees.
                    </td>
                    <td>
                      <a href="tel:9549654394">9549654394</a>
                    </td>
                    <td>
                      <a href="mailto:nmittal.cse@mnit.ac.in">
                        nmittal.cse@mnit.ac.in
                      </a>
                    </td>
                    <td>Presiding Officer</td>
                  </tr>
                  <tr>
                    <th>Dr. Vinita Tiwari</th>
                    <td rowSpan="2" style={{ verticalAlign: "middle" }}>
                      Not less than two members from amongst employees
                      preferably committed to the cause of women or who had
                      experience in social work or have legal knowledge.
                    </td>
                    <td>
                      <a href="tel:9549654678">9549654678</a>
                    </td>
                    <td>
                      <a href="mailto:vinitatiwari@iiitkota.ac.in">
                        vinitatiwari@iiitkota.ac.in
                      </a>
                    </td>
                    <td>Member</td>
                  </tr>
                  <tr>
                    <th>Dr. Isha Pathak Tripathi</th>
                    <td>
                      <a href="tel:9549650267">9549650267</a>
                    </td>
                    <td>
                      <a href="mailto:isha@iiitkota.ac.in">
                        isha@iiitkota.ac.in
                      </a>
                    </td>
                    <td>Member</td>
                  </tr>
                  <tr>
                    <th>Dr. Rashmi Jain</th>
                    <td>
                      One member from amongst non-government organizations or
                      association committed to the cause of women or a person
                      familiar with the issues relating to sexual harassment.
                    </td>
                    <td>
                      <a href="tel:9414203434">9414203434</a>
                    </td>
                    <td>
                      <a href="mailto:rashmijn1@gmail.com">
                        rashmijn1@gmail.com
                      </a>
                    </td>
                    <td>External Member</td>
                  </tr>
                </tbody>
              </table>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Iccw;
