import React from "react";
import { Card, CardBody, Container, Row, Col, Table } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "bootstrap";
import "assets/css/style.css";
import Helmet from "react-helmet";
import Cards from 'react-bootstrap/Card';

class Antiragging extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Antiragging - Indian Institute of Information Technology Kota</title>
          <meta name="description" content="Antiragging Page of IIIT Kota" />
          <meta
            name="keywords"
            content="Antiragging, IIIT Kota Antiragging, Antiragging File of IIIT Kota, Indian Institute of Information Technology Kota Antiragging"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Anti-Ragging Campaign</b>
                  </h2>
                </Col>
              </Row>
            </Container>

            <Container>
                <h4 style={{textAlign:"center"}}><b>Ragging is a crime!!!</b></h4>
                <br/><br/>
                <h6 style={{textAlign:"justify"}}>
                In view of the directions of Hon'ble Supreme Court of India in the matter
                 of "University of Kerala Vs Council, Principals, Colleges & Others" in SLP
                  no. 24295/2006 dated 16.05.2007 and dated 08.05.2009 in Civil Appeal no. 
                  887/2009 and in consideration of the determination of Govt. of India and 
                  University Grants Commission to prohibits, prevent and eliminate the scourge
                   of ragging, UGC Regulations on curbing the menace of Ragging in Higher Educational
                    Institutions in 2009 came to be issued and which were published in the Gazette of 
                    India on 04.07.2009. The said regulations are applicable on all the Institutions
                     coming within the definition of University, Deemed University and all other Higher
                      Educational Institutions, including IIIT Kota. Section 3 of said regulations 
                      provides that Ragging constitutes one or more of any of the following acts:
                      <br/><br/>
                    <ol type="a">
                        <li>Any conduct by any student or students whether by words spoken or written 
                            or by an act which has the effect of teasing, treating or handling with 
                            rudeness a fresher or any other student.
                        </li>
                        <li>Indulging in rowdy or Undisciplined activities by any student or students
                             which causes or is likely to cause annoyance, hardship, physical or psychological
                              harm or to raise fear or apprehension thereof in any fresher or any other student.
                        </li>
                        <li>Asking any student to do any act which such student will not in the ordinary course
                             do and which has the effect of causing or generating a sense of shame, or torment 
                             or embarrassment so as to adversely affect the physique or psyche of such fresher 
                             or any other student.
                        </li>
                        <li>Any act by a senior student that prevents, disrupts or disturbs the regular academic activity of any other student or a fresher.</li>
                        <li>Exploiting the services of a fresher or any other student for completing the academic tasks assigned to an individual or a group of students.</li>
                        <li>Any act of financial extortion or forceful expenditure burden put on a fresher or any other student by students</li>
                        <li>Any act of physical abuse including all variants of it: sexual abuse, homosexual 
                            assaults, stripping, forcing obscene and lewd acts, gestures, causing bodily harm 
                            or any other danger to health or person.
                        </li>
                        <li>Any act or abuse by spoken words, emails, post, public insults which would also 
                            include deriving perverted pleasure, vicarious or sadistic thrill from actively 
                            or passively participating in the discomfiture to fresher or any other student.
                        </li>
                        <li>
                        Any act that affects the mental health and self-confidence of a fresher or any other student.
                        </li>
                    </ol>
                    <br/>
                    Ragging is totally prohibited in IIIT Kota and if any student is found indulged in activity
                     of Ragging then severe action will taken including lodging of FIR against such student/
                     students. At the time of registration, students and their parents have given an undertaking
                      that he/she will not indulge in any kind of activity which amounts to ragging. Thus, it is 
                      requested that all the students of Institution will be watchful and help the administration 
                      in all possible ways to curb and to root out ragging in all its forms. For this purpose and 
                      in case of any emergency, please contact at the following numbers/officials:

                  <br/><br/>
                  <Cards className="shadow">
                    <Cards.Header><b>Security Cell</b></Cards.Header>
                    <Cards.Body>
                      <Table responsive>
                        <tbody>
                          <tr className="border">
                            <td className="border">
                              <h6><b>Nodal Officer (Anti-ragging)</b></h6>
                            </td>
                            <td className="border">
                            <h6><b>Faculty In charge Security</b></h6>
                            </td>
                            <td className="border">
                            <h6><b>Security In-charge</b></h6>
                            </td>
                            <td className="border">
                            <h6><b>Security Control Room</b></h6>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Cards.Body>
                  </Cards>


                  <br/><br/>
                  <b>Anti-ragging website link:</b>&nbsp;&nbsp;
                  <a href="https://www.antiragging.in">https://www.antiragging.in</a>


                  <br/><br/>

                  The National Anti-Ragging Helpline :
                  The National Anti-Ragging Help Line 24x7 Toll Free number is 1800-180-5522
                   or e-mail to helpline@antiragging.in . The support is provided for queries 
                   related to ragging, compliant registration, among others.
                   
                   <br/><br/>

                  On receipt of any information concerning any reported incident of ragging,
                   the administration of IIIT Kota shall immediately determine, if a case under the
                    penal laws is made out and if so, a FIR shall be lodged with Police and local
                     authorities under the appropriate penal provisions relating to one or more
                      of the following namely:

                  <br/><br/>

                  <ol>
                    <li>Abetment to ragging.</li>
                    <li>Criminal conspiracy to rag.</li>
                    <li>Unlawful assembly and rioting while ragging.</li>
                    <li>Public nuisance created during ragging.</li>
                    <li>Violation of decency and morals through ragging.</li>
                    <li>Injury to body, causing hurt or grievous hurt.</li>
                    <li>Wrongful restraint.</li>
                    <li>Wrongful confinement.</li>
                    <li>Use of criminal force.</li>
                    <li>Assault, as well as sexual offences or unnatural offences.</li>
                    <li>Extortion, criminal trespass.</li>
                    <li>Offences against property.</li>
                    <li>Criminal intimidation.</li>
                    <li>Attempts to commit any or all of the mentioned offences against the victims(s).</li>
                    <li>Threat to commit any or all of the above mentioned offences against the victim (s).</li>
                    <li>Physical or psychological humiliation.</li>
                    <li>All other offences covered under the definition of "Ragging".</li>
                  </ol>

                  <br/><br/>

                  Further, other than lodging FIR the administration shall punish a student 
                  found guilty of ragging and shall take an appropriate decision in regard to
                  punishment or otherwise depending on the nature and gravity of the guilt and
                  one or more of the following punishments may be awarded:
                  
                  <ol>
                    <li>Suspension from attending classes & academic privileges.</li>
                    <li>Withholding/ withdrawing scholarships/ fellowship and other benefits.</li>
                    <li>Debarring from appearing in any test/ examination or other evaluation process.</li>
                    <li>Withholding results.</li>
                    <li>Debarring from representing the Institute in any regional, national or international meet, tournament youth festival etc.</li>
                    <li>Suspension/ expulsion from the hostel.</li>
                    <li>Cancellation of admission.</li>
                    <li>Rustication from the Institution for period ranging from 1 to 4 semesters.</li>
                    <li>Expulsion from the Institutional and consequent debarring from admission to any other institution for a specific period.</li>
                    <li>Fine ranging between Rs. 25,000 to Rs. 1 Lakh.</li>
                  </ol>

                
                    
                        <Row>
                          <Col lg={9}></Col>
                          <Col lg={3}>
                          <h5 style={{textAlign:"center"}}><b>By Order</b></h5>
                          <h5 style={{textAlign:"center"}}><b>S/d</b></h5>
                          <h5 style={{textAlign:"right"}}><b>Dean Student welfare</b></h5>
                          </Col>
                        </Row>
                          
                       
                    
                  
                </h6>

                <br/><br/>

                <ul>
                  <a href="https://files.iiitkota.ac.in/pdf/ragging/UGC Rules & regulations on Curbing the Menace of Ragging.pdf"><li>Rules & Regulation on Curbing the Menace of Ragging</li></a>
                  <a href="https://files.iiitkota.ac.in/pdf/ragging/Warden_order.pdf"><li>List of appointed wardens for Hostels</li></a>
                  <a href="https://files.iiitkota.ac.in/pdf/ragging/committee.pdf"><li>Office order of Anti Ragging Committe of A.Y. 2023-24</li></a>
                  <a href="https://files.iiitkota.ac.in/pdf/ragging/Revise Warden_order.pdf"><li>Revised Office Order No 8161-69 dated 15.04.2024 Hostel Wardens</li></a>
                  <a href="https://files.iiitkota.ac.in/pdf/ragging/Revise Warden order -2nd.pdf"><li>Revised Office Order No 9488-95 dated 05.08.2024 Hostel Wardens</li></a>
                  <a href="https://files.iiitkota.ac.in/pdf/ragging/ContactDetails_Ragging.pdf"><li>Contact details to approch regarding ragging</li></a>
                  <a href="https://files.iiitkota.ac.in/pdf/ragging/Contact_HostelAdministration_pdf.pdf"><li>Contact details of Hostel Administration</li></a>
                  <a href="https://files.iiitkota.ac.in/pdf/ragging/squad.pdf"><li>Contact No. of Anti Ragging Squads for Academic Year 2023-24</li></a>
                  <a href="https://files.iiitkota.ac.in/pdf/ragging/ARA_CollaterePosters.pdf"><li>Awareness collaterals Posters</li></a>
                  <a href="https://antiragging.in/video.html"><li>Anti-Ragging Video link</li></a>
                </ul>

                <Container><img src={require("assets/img/antiragging/ARA_CollaterePosters.jpg")}></img></Container>
                
            </Container>
           
            
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Antiragging;
