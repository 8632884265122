import React from "react";
import "views/IndexSections/Sections.css";
import { Button, Card, Container, Row, Col } from "reactstrap";
import Bounce from "react-reveal/Bounce";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Visitor extends React.Component {
    state = {};
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        Visitor - Indian Institute of Information Technology, Kota
                    </title>
                    <meta
                        name="description"
                        content="Visitor - Indian Institute of Information Technology, Kota"
                    />
                    <meta
                        name="keywords"
                        content="Visitor, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota, Gaurav Parmar, thegauravparmar, Omkar Deshmukh, Parth Mangal, Mayank Goyal, Pranjal Agarwal, 2019kucp1026, 1029kucp1019, 2019kucp1009, 2018kucp1004, 2018kucp1127"
                    />
                </Helmet>
                <DemoNavbar />
                <main ref="main">
                    <div className="position-relative">
                        {/* shape Hero */}
                        <section className="section  section-shaped pb-200">
                            <div className="shape shape-style-1 shape-default bg-gradient-default">
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                            </div>

                            <div className="separator separator-bottom separator-skew">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    preserveAspectRatio="none"
                                    version="1.1"
                                    viewBox="0 0 2560 100"
                                    x="0"
                                    y="0"
                                >
                                    <polygon className="fill-white" points="0 0 0 0 0" />
                                </svg>
                            </div>
                        </section>
                    </div>
                    <section className="section section-lg">
                        <Container>
                            <Row className="justify-content-center text-center mb-5">
                                <Col lg="8">
                                    <h2>
                                        <b>Visitor</b>
                                    </h2>
                                    {/* <p className="lead text-muted">
                    According to the National Oceanic and Atmospheric
                    Administration, Ted, Scambos, NSIDClead scentist, puts the
                    potentially record maximum.
                  </p> */}
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                                    <div className="px-4">
                                        <img
                                            alt="..."
                                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                            src={require("assets/img/people/administrating_body/visitor.jpg")}
                                            style={{ width: "200px" }}
                                        />
                                        <div className="pt-4 text-center">
                                            <h5 className="title">
                                                <span className="d-block mb-1">Smt. Droupadi Murmu</span>
                                                <small className="h6 text-muted">
                                                    Honourable President of India
                                                </small>
                                            </h5>
                                            <div className="mt-3">
                                                <Button
                                                    className="btn-icon-only rounded-circle theme-color-kota-bg"
                                                    href="https://presidentofindia.nic.in/"
                                                    target="_blank"
                                                >
                                                    <i className="fas fa-address-card"></i>
                                                </Button>

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
                <NewFooter />
            </>
        );
    }
}

export default Visitor;
