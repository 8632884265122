import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Sports extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Sports - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Sports Labs Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Sports, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,IIIT Kota Sports Team,Cricket Team,Sports in IIIT Kota,Badminton, Football,Volleyball,Chess,Athletics"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Sports</b>
                  </h2>
                </Col>
              </Row>


              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h4>
                    <b>IIIT Kota at MST 2023, MNIT Jaipur</b>
                  </h4>
                </Col>
                <Col lg={12}>
                  <h6>
                    Students of IIIT Kota participated in MST 2023 held at
                    MNIT Jaipur.
                  </h6>
                </Col>
                <Col lg={12}>
                  <h6>
                    Total 75 students participated in various games (Athletics,
                    Badminton, Chess, Cricket, Table Tennis and Carrom).
                  </h6>
                </Col>
                <Col lg={6} className="text-center mt-5">
                  <img
                    src="https://files.iiitkota.ac.in/images/Sports/mst/mst-main.jpg"
                    alt="IIIT Kota at MST 2023"
                    width="100%"
                  />
                </Col>
                <Col className="mt-5" lg={12}>
                  <h5>
                    <b>Achievements:-</b>
                  </h5>
                </Col>
                <Col lg={4} className="mt-2">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Silver medal
                    </b>
                  </h6>

                  <h6>
                    <ol>
                      <li>Lawn Tennis </li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={4} className="mt-2">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Bronze medal
                    </b>
                  </h6>
                  <h6>
                    <ol>
                      <li>Table Tennis </li>
                      <li>Kabaddi</li>
                      <li>Badminton</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={4} className="mt-3">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Other Achievements
                    </b>
                  </h6>
                  <h6>
                    <ol>
                      <li>Played Semifinals of Football</li>
                    </ol>
                  </h6>
                </Col>
              </Row>

              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h4>
                    <b>IIIT Kota at Varchas 2022, IIT Jodhpur</b>
                  </h4>
                </Col>
                <Col lg={12}>
                  <h6>
                    Students of IIIT Kota participated in Varchas 2022 held at IIT Jodhpur.
                  </h6>
                </Col>
                <Col lg={12}>
                  <h6>
                    Total 71 students participated in various games (Athletics,
                    Badminton, Chess, Cricket, Table Tennis and Carrom).
                  </h6>
                </Col>
                <Col lg={6} className="text-center mt-5">
                  <img
                    src="https://files.iiitkota.ac.in/images/Sports/varchas/varchas-main.jpg"
                    alt="IIIT Kota at Varchas 2022"
                    width="100%"
                  />
                </Col>
                <Col className="mt-5" lg={12}>
                  <h5>
                    <b>Achievements:-</b>
                  </h5>
                </Col>
                <Col lg={6} className="mt-2">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Silver medal
                    </b>
                  </h6>

                  <h6>
                    <ol>
                      <li>800m Boys (Himanshu Saini)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={6} className="mt-2">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Bronze medal
                    </b>
                  </h6>
                  <h6>
                    <ol>
                      <li>4*400m Relay Boys (Team - HarshDeep Singh , Vikas Patel ,Gauri Shankar Tak and Himanshu Saini)</li>
                      <li>400m Girls (Ashpreet Kaur)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={12} className="mt-3">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Other Achievements
                    </b>
                  </h6>
                  <h6>
                    <ol>
                      <li>Played Quarter-Final in Volleyball</li>
                      <li>Played Semi-Final in Table Tennis and Badminton</li>
                    </ol>
                  </h6>
                </Col>
              </Row>

              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h4>
                    <b>IIIT Kota at BOSM-2022, BITS Pilani</b>
                  </h4>
                </Col>
                <Col lg={12}>
                  <h6>
                    Students of IIIT Kota participated in BOSM 2022 held at
                    Birla Institute of Technology And Science, Pilani during October 14-18, 2022.
                  </h6>
                </Col>
                <Col lg={12}>
                  <h6>
                    Total 88 students participated in various games (Athletics,
                    Badminton, Chess, Cricket, Table Tennis and Carrom).
                  </h6>
                </Col>
                <Col lg={6} className="text-center mt-5">
                  <img
                    src="https://files.iiitkota.ac.in/images/Sports/bosm/bosm-main.jpg"
                    alt="IIIT Kota at BOSM-2022, BITS Pilani"
                    width="100%"
                  />
                </Col>
                <Col className="mt-5" lg={12}>
                  <h5>
                    <b>Achievements:-</b>
                  </h5>
                </Col>
                <Col lg={6} className="mt-3">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Gold medal
                    </b>
                  </h6>
                  <h6>
                    <ol>
                      <li>PowerLifting (Ayush Kumar Mohanta)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={6} className="mt-2">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Silver medal
                    </b>
                  </h6>

                  <h6>
                    <ol>
                      <li>Long Jump (Piyush Maheshwari)</li>
                      <li>Triple Jump (Piyush Maheshwari)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={6} className="mt-3">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Bronze medal
                    </b>
                  </h6>
                  <h6>
                    <ol>
                      <li>Shotput Girls (Oshika Singh)</li>
                      <li>PowerLifting (Avijit Singh Yadav)</li>
                      <li>Javelin Throw Boys (Dharmveer Singh)</li>
                      <li>1500m Boys (Himanshu Saini)</li>
                      <li>4*100m Girls Relay (Team- Deepika,Iresha,Jhanvi & Rakshita)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={6} className="mt-3">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Other Achievements
                    </b>
                  </h6>
                  <h6>
                    <ol>
                      <li>Played Semi-Final in Football</li>
                    </ol>
                  </h6>
                </Col>
              </Row>

              {/* commenting since image is not valid */}

              {/* <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h4>
                    <b>IIIT Kota at Gusto-2017, IIITDM Jabalpur</b>
                  </h4>
                </Col>
                <Col lg={12}>
                  <h6>
                    Students of IIIT Kota participated in Gusto 2017 held at
                    Indian Institute of Information Technology, Design and
                    Manufacturing (IIITDM), Jabalpur during January 27-29, 2017.
                  </h6>
                </Col>
                <Col lg={12}>
                  <h6>
                    Total 38 students participated in various games (Athletics,
                    Badminton, Chess, Cricket, Table Tennis and Carrom).
                  </h6>
                </Col>
                <Col lg={6} className="text-center mt-5">
                  <img
                    src="http://210.212.97.132/images/sport1.jpg"
                    alt="IIIT Kota at Gusto-2017, IIITDM Jabalpur"
                    width="100%"
                  />
                </Col>
                <Col className="mt-5" lg={12}>
                  <h5>
                    <b>Achievements:-</b>
                  </h5>
                </Col>
                <Col lg={6} className="mt-2">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Silver medal
                    </b>
                  </h6>

                  <h6>
                    <ol>
                      <li>100 m race (Navneet Poonia)</li>
                      <li>200 m race (Navneet Poonia)</li>
                      <li>400 m race (Rahul Tyagi)</li>
                      <li>800 m race (Mohit Choudhary)</li>
                      <li>Carrom (Anjali Garg)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={6} className="mt-3">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Bronze medal
                    </b>
                  </h6>
                  <h6>
                    <ol>
                      <li>100 m race (Deepak Yadav)</li>
                      <li>800 m race (Jaipal Singh)</li>
                    </ol>
                  </h6>
                </Col>
              </Row> */}

              {/* update below code with latest inter iiit details */}
              {/* add image url: https://files.iiitkota.ac.in/images/Sports/inter/inter-main.jpg */}
              {/* <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h4>
                    <b>
                      IIIT Kota stands 3rd overall at Inter IIIT Sports Meet –
                      2016
                    </b>
                  </h4>
                </Col>
                <Col lg={12}>
                  <h6>
                    Students of IIIT Kota participated at Inter IIIT Sports Meet
                    – 2016 held at IIITDM Kancheepuram during Dec 13-15, 2016.
                    Team IIIT Kota ranked 3rd overall and institute is proud of
                    this achievement.
                  </h6>
                </Col>
                <Col lg={12}>
                  <h6>
                    Total 34 students participated in various games (athletics,
                    badminton, chess, cricket, etc.).
                  </h6>
                </Col>
                <Col lg={8} className="text-center mt-5">
                  <img
                    src=""
                    alt="IIIT Kota at Gusto-2017, IIITDM Jabalpur"
                    width="100%"
                  />
                </Col>
                <Col className="mt-5" lg={12}>
                  <h5>
                    <b>Achievements:-</b>
                  </h5>
                </Col>
                <Col lg={4} className="mt-2">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Gold medal
                    </b>
                  </h6>
                  <h6>
                    <ol>
                      <li>Long jump (Navneet Poonia)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={4} className="mt-2">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Silver medal
                    </b>
                  </h6>
                  <h6>
                    <ol>
                      <li>4*400 (boys team)</li>
                      <li>Shotput (Srikant Deshmane)</li>
                      <li>Chess (Nidhi Mantri)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={4} className="mt-2">
                  <h6>
                    <b>
                      <i class="fas fa-medal px-2"></i>Bronze medal
                    </b>
                  </h6>
                  <h6>
                    <ol>
                      <li>Cricket (boys team)</li>
                      <li>Long jump (Parth Kumar)</li>
                      <li>Badminton doubles (girls team)</li>
                    </ol>
                  </h6>
                </Col>
              </Row> */}
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Sports;
