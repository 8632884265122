import React from "react";
import "assets/css/style.css";
import { Button, Container, Row, Col, Card, CardBody } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class PgProcedure extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            PG ADMISSIONS - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="PG Admissions - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="PG Admissions iiit kota, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-250">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="10">
                  <h2>
                    <b>PG Admissions</b>
                  </h2>
                  <h2>
                    <b>2024-25</b>
                    <b>
                      <span style={{ color: "red", marginLeft: "4px" }}> </span>
                    </b>
                  </h2>
                  {/* <br />
                  <h5>
                    <b>
                      PG Admission Procedure during Aug 08-12, 2022 9.00 AM -
                      5.00 PM on all days
                    </b>
                  </h5>
                  <br />
                  <h6>
                    <b>
                      INSTRUCTIONS TO CANDIDATES FOR ADMISSION IN M.TECH.
                      PROGRAMME 2022-23 AT IIIT KOTA THROUGH CCMT2022
                    </b>
                  </h6> */}
                </Col>
              </Row>
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          M.Tech. in Computer Science and Engineering with
                          specialization in Artificial Intelligence and Data
                          Science
                        </b>
                      </h6>
                      <h7>
                        <b>
                          1. Admission to M.Tech. Full-time (Institute
                          Assistantship) programme via CCMT 2024
                        </b>
                      </h7>
                      <ul>
                        <li>
                          CCMT 2024 :{" "}
                          <a href="https://ccmt.admissions.nic.in/">Link</a>
                        </li>
                        <li>
                          CCMT 2024 :{" "}
                          <a href="https://ccmt.admissions.nic.in/information-brochure-4/">
                            Information Brochure
                          </a>
                        </li>
                        <li>
                          CCMT 2024 :{" "}
                          <a href="https://ccmt.admissions.nic.in/schedule/">
                            Counselling Schedule
                          </a>
                        </li>
                        <li>
                          Fee Structure for CCMT 2024 candidates :{" "}
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://files.iiitkota.ac.in/pdf/fees/_Fee%20structure%20MTech_2024-25-ccmt.pdf"
                          >
                            Institute Fees
                          </a>
                          {" and "}
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://files.iiitkota.ac.in/pdf/pg/Hostel Fees 2024-25.pdf"
                          >
                            Hostel Fees
                          </a>
                        </li>
                        <li>
                          CCMT 2024 :{" "}
                          <a href="https://files.iiitkota.ac.in/pdf/pg_contact/contact_details.docx">
                            Contact Details
                          </a>
                        </li>

                        <li>
                          Date for Physical Reporting :{" "} August 6 to 14 of 2024
                          {/*  17th, 18th and 21st
                          August 2023 [Please refer to the Academic Calendar for
                          Ist Sem PG Students (Odd Sem 2023-24)]   */}
                        </li>
                        <li>
                          Venue for Physical Reporting : IIIT Kota, SPL-269,
                          RIICO Industrial Area, Kuber Extension, Ranpur, Kota –
                          325003, Rajasthan{" "}
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://www.google.com/maps/place/IIIT+Kota+permanent+campus/@25.0498253,74.7252207,9z/data=!4m10!1m2!2m1!1siiit+kota+kota!3m6!1s0x396f874a7d8fde0d:0x471f3c2337f358e8!8m2!3d25.0498253!4d75.8267351!15sCg5paWl0IGtvdGEga290YZIBBnNjaG9vbOABAA!16s%2Fg%2F11gy9z31n0?entry=ttu"
                          >
                            Google Map
                          </a>
                        </li>

                        <li>
                          List of Documents required at the time of Physical Reporting:{" "}

                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://files.iiitkota.ac.in/pdf/pg/List of Documents For PG Admission (1).pdf"
                          >
                            link
                          </a>

                        </li>

                        <li>Orientation Programme and Commencement of classes:{" "}16th August 2024 </li>
                        <li>Procedure for Fees (Institute and Hostel) Payment :{" "}

                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=646985"

                          >
                            Link
                          </a>

                        </li>
                      </ul>
                      <h7>
                        <b>
                          2. Admission to M.Tech. Full-time (Sponsored) /
                          Part-time (Sponsored) programme via Institute PG
                          Admission Test 2024
                        </b>
                      </h7>
                      <ul>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/pg/PG(sponsored) and Phd Admission_InformationBrochure_2024-25 Updated.pdf">
                            Admission Information Brochure{" "}
                          </a>
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/fees/Fee_structure_MTech_2024-25_sposored.pdf">
                            FEE Structure{" "}
                          </a>
                        </li>
                        <li><a href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=646985">Pay Application Fees</a></li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/pg/MTech Application Form 2024-25.pdf">
                            Application Form(To be uploaded by the applicant){" "}
                          </a>
                        </li>

                        <li>
                          <a href="https://forms.gle/vfahwjxfjyBrVZVG9">
                            Apply Online
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Ph.D. in Computer Science and Engineering /
                          Electronics and Communication Engineering
                        </b>
                      </h6>
                      <h7>
                        <b>
                          1.{" "}
                          <span class="aligned-text">
                            Admission to Ph.D. Full-time (Institute
                            Assistantship) / Full-time (Sponsored) / Part-time
                            (Sponsored) programme via Institute PG Admission
                            Test 2024
                          </span>
                        </b>
                      </h7>
                      <ul>
                        <li >
                          
                          
                            Result of PhD Admission 2024-25 : <a href="https://files.iiitkota.ac.in/pdf/pg/Annexure.pdf">Link</a>
                          
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/pg/PG(sponsored) and Phd Admission_InformationBrochure_2024-25 Updated.pdf">
                            Admission Information Brochure
                          </a>
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/fees/Fee structure PhD_2024-25.pdf">
                            Fee Structure
                          </a>
                        </li>
                        <li><a href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=646985">Pay Application Fees</a></li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/Phd/PhD Application Form 2024-25.pdf">
                            Application Form (To be uploaded by the applicant)
                          </a>
                        </li>
                        <li>
                          {/* <a href="https://forms.gle/vfahwjxfjyBrVZVG9"> */}
                          Apply Online
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                          {/* </a> */}
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/Phd/ECE_Shortlisted4OfflineWrittenTest2024.pdf" target="blank">
                            List of Provisionally Shortlisted Candidates Eligible to appear for Offline Written Test in Dept. of ECE for Admission to Ph.D. Part-time (Sponsored) Programme 2024-25
                          </a>
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/Phd/CSE_Shortlisted4OfflineWrittenTest2024.pdf" target="blank">
                            List of Provisionally Shortlisted Candidates Eligible to appear for Offline Written Test in Dept. of CSE for Admission to Ph.D. Full-time (Institute Assistantship) Programme 2024-25
                          </a>
                        </li>
                        {/* <li>
                          <a href="https://files.iiitkota.ac.in/pdf/PhD_ProvisionalCandidates4WrittenExam2023.pdf">
                            List of Provisionally Shortlisted Candidates
                          </a>
                        </li> */}
                        {/* <li>
                          <b>Closing Date</b>: 16th June, 2023
                          <b>Closed</b>
                        </li>  */}
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {/* <Row className="justify-content-center text-justify"></Row>
              <Row className="justify-content-center mt-5 text-justify">
                <Col lg={12} xs={12}>
                  <h4>
                    <b>
                      Please follow the following steps carefully for online
                      institute reporting-
                    </b>
                  </h4>
                </Col>
                <Col lg={12} xs={12} className="mt-3">
                  <h6>
                    <ol>
                      <li>
                        Institute reporting for M.Tech admission is for all
                        candidates. Either they got their seat through CCMT
                        2022.
                      </li>
                      <li>
                        It is mandatory for every candidate, who wish to take
                        admission in IIIT Kota, to pay the remaining Institute
                        Fee.
                        <br />
                        The remaining Institute Fee = Institute Fee – (CCMT seat
                        acceptance fee, if paid). (Click here to pay the
                        remaining Institute Fee).
                        <br />
                        <a href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=646985">
                          (Click here to pay the remaining Institute Fee).
                        </a>
                      </li>
                      <li>
                        Take the receipt of CCMT seat acceptance fee (as
                        applicable). Also take the receipt of remaining
                        institute fee mentioned above in Step 2.
                      </li>
                      <li>
                        Get ready with the self-attested scan copy of all these
                        fee receipts. No hostel fee is required to pay right
                        now. The institute shall intimate about the hostel fee
                        later, if available.
                      </li>
                      <li>
                        Get ready with the photocopies of the list of documents
                        and self-attest them in the given ORDER.
                        <a href="#">
                          (Click here to get the list of required documents).
                        </a>
                      </li>

                      <li>
                        Get ready with the hard copy of filled Admission Forms
                        and self-attest every pages.
                        <a href="#">
                          (Click here to get the text of Affidavit).
                        </a>
                      </li>
                      <li>
                        A separate Affidavit on Non-Judicial Stamp of Rs. 50/-
                        or above is also required for gap, if the candidate has
                        gap in his study..{" "}
                        <a href="#">
                          (Click here to get the text of Gap Affidavit).
                        </a>
                      </li>
                      <li>
                        If you do not have one or more documents right now, you
                        have to give an undertaking that you will provide these
                        documents latest by 31st Aug 2022.. 
                        <a href="#">
                          (Click here to get the Undertaking Form).
                        </a>
                      </li>
                      <li>
                        Arrange all the documents in the following order:
                        <br />
                        Filled Admission Forms (Step 6)
                        <br />
                        Affidavit for Gap, if applicable (Step 7)
                        <br />
                        Fee receipts (Step 2)
                        <br />
                        List of documents (Step 5)
                        <br />
                        Undertaking, if required (Step 8)
                        <br />
                      </li>

                      <li>
                        Provisional admission confirmation will be communicated
                        by email.
                      </li>
                      <li>
                        Commencement of classes shall be notified soon. Kindly
                        follow the institute website for latest updates.
                      </li>
                      <li>
                        Candidate arriving in MNIT Jaipur has to come to Prabha
                        Bhawan, II Floor, IIIT Kota Office.
                      </li>
                    </ol>
                    <br />
                  </h6>
                </Col>
                <Col lg={12} xs={12}>
                  <h5>
                    <b>Important Dates and Announcements</b>
                  </h5>
                  <h6>
                    <ol>
                      <li>Reporting dates are 8th to 12th August.</li>
                      <li>
                        Hostel facility is on 1'st come first server basis and
                        as per availability.
                      </li>
                      <li>Orientation programme - September 15, 2022.</li>
                      <li>Course Registration - September 16, 2022.</li>
                      <li>Commencement of Classes - September 19, 2022.</li>
                    </ol>
                  </h6>
                  <br />
                </Col>
                <Col lg={12} xs={12}>
                  <h5>
                    <b>For fee related help, you can contact</b>
                  </h5>
                  <h6>
                    <ol>
                      <li>Dr. Parikshit Kishor Singh – 9549659156</li>
                      <li>Dr. Priyanka Mishra – 9549650567</li>
                    </ol>
                  </h6>
                  <br />

                  <h6>
                    <b>Note:</b> Working hours 9:00 AM to 5:00 PM (Lunch Break-
                    1:00 to 2:00 PM).).
                  </h6>
                </Col>
              </Row> */}
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default PgProcedure;
