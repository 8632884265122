import React from "react";
import { Card, CardBody, Container, Row, Table, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "views/IndexSections/Sections.css";
import Helmet from "react-helmet";
class PostOffice extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            PostOffice - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Post Office Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Post Office, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Post Office in IIIT Kota,IIIT Kota Post Office,Post Office in IIIT Kota"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Post Office</b>
                  </h2>
                </Col>
              </Row>
                <div className=" d-flex justify-content-center mt-3">
                    <img className="rounded img-fluid "src={require("assets/img/facilities/postoffice.webp")} alt="/" width={300}/>
                </div>
            </Container>
            <Container>
              <Row>
                <Col lg={12} xs={12}>
                  <h6>
                  There is a Post Office in the campus of MNIT.
                  </h6>
                </Col>
                <Col lg={12} xs={12} className="mt-3 mb-3">
                      <b>Post Office offers:</b>
                      <ul style={{ listStyleType: 'decimal' , marginLeft:"-20px" }}>
                        <li>Mail-related services such as Speed Post, Reg.</li>
                        <li>Sale of postage stamps, Postal orders.</li>
                        <li>NSC</li>
                        <li>RD/SB/MIS Accounts.</li>
                      </ul>
                      <b>The postal code of institute is: 302017</b>
                </Col>
                <Col lg={12} xs={12} className="mt-3 mb-3">
                      <b>Timings</b>
                      <p>9.00 am - 5.00 pm (Monday - Friday) <br/>
                        9.00 am - 2.00 pm (Saturday)</p>
                </Col> 
              </Row>

              <Row className="justify-content-center text mb-5 ">
                <center>
                  <h4 className=" mb-5">
                    <b>Contact Information:</b>
                  </h4>
                </center>
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 pl-4">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 text-primary">
                            <i className="fas fa-address-book" />
                          </div>

                          <h6 className="text-primary text-uppercase">
                            <b>Post Masterr</b>
                            <br />
                          </h6>

                          <div className="px-2 pb-2">
                            Post Office
                            <br />
                            Malaviya National Institute of Technology JLN Marg,
                            Jaipur-302017
                          </div>

                          <h6>
                            <b>Phone no:</b>{" "}
                            <a href="tel:0141-2529165"> 0141 - 2529165</a>
                            <br />
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default PostOffice;
