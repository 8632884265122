import React from "react";
import { arrayOf, object } from "prop-types";

const Skills = (props) => {
  const { skillsData } = props;
  var getSkills = "";
  if (skillsData) {
    getSkills =
      skillsData[0].keywords &&
      skillsData[0].keywords.map(function (item, index) {
        return (
          <li key={index}>
            <span className="label label-primary">{item}</span>
          </li>
        );
      });
  }
  return (
    <>
      {getSkills != "" && (
        <section
          className="section skills"
          style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
        >
          <h2 className="text-uppercase h2">
            <i className="fa fa-lg fa-code"></i> Skills
          </h2>
          <ul className="ul skills-list">{getSkills}</ul>
        </section>
      )}
    </>
  );
};

Skills.propTypes = {
  skillsData: arrayOf(object).isRequired,
};

export default Skills;
