import axios from "axios";
let eventGuid = 0;
// let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export const INITIAL_EVENTS = async () => {
  const All = await axios.get(
    "https://raw.githubusercontent.com/thegauravparmar/fileskota/main/calendar.json?token=ANB54C6E5IEP34VXNONGE43ARKJLU"
  );
  const EventsAll = All.data;
  console.log(EventsAll);
  return EventsAll.Events;
};
export function createEventId() {
  return String(eventGuid++);
}
