/* eslint-disable */
import React from "react";
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import axios from "axios";
import Helmet from "react-helmet";
class Faculty extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Faculties - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Faculties - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="faculties iiit kota, cse faculty iiit kota, ece faculty iiit kota, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Deans</b>
                  </h2>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="d-flex justify-content-center ">
                    <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <a
                          target="_blank"
                          href="https://mnit.ac.in/dept_cse/profile?fid=RKSK"
                        >
                          <CardBody>
                            <div className="mb-4 text-center">
                              {/* <i className="ni ni-check-bold" /> */}
                              <img
                                className="faculty-img"
                                height="300px"
                                alt=""
                                src={require("../../../assets/img/people/administrating_body/Dr.-Pilli-Emmanuel-Shubhakar.webp")}
                              />
                            </div>
                            <div className="text-center">
                              <h6 className="text-default ">
                                <b>Dr. Pilli Emmanuel Shubhakar</b>
                              </h6>
                            </div>
                          </CardBody>
                        </a>
                      </Card>
                    </Col>
                    <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <a
                          target="_blank"
                          href="https://mnit.ac.in/dept_ee/profile?fid=RK2L"
                        >
                          <CardBody>
                            <div className="mb-4 text-center">
                              {/* <i className="ni ni-check-bold" /> */}
                              <img
                                className="faculty-img"
                                height="300px"
                                alt=""
                                src={require("../../../assets/img/people/administrating_body/Dr.-Rohit-Bhakar.webp")}
                              />
                            </div>
                            <div className="text-center">
                              <h6 className="text-default ">
                                <b>Prof. Rohit Bhakar</b>
                              </h6>
                            </div>
                          </CardBody>
                        </a>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Faculty;
