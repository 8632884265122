import React from "react";
import { Container, Row, Col, Table } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import Helmet from "react-helmet";
class ExecutiveCommittee extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Executive Committee - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Executive Committee - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Executive Committee, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Executive Committee</b>
                  </h2>
                </Col>
              </Row>

              <Row>
                <Col lg={12} xs={12} className="mt-5 mb-5">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "101px" }}>S.No</th>
                          <th style={{ width: "555.5px" }}>Name</th>
                          <th style={{ width: "353.5px" }}> Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>1.</th>
                          <td>
                            <b>Prof. N. P. Padhy (Ex-Officio)</b>
                            <br />
                            <a href="/director">Director, IIIT Kota</a>
                          </td>
                          <td>Chairman</td>
                        </tr>
                        <tr>
                          <th>2.</th>
                          <td>
                            <b>Mr. Sudhir Mathur</b>
                            <br />
                            <a
                              href="https://www.cairnindia.com/"
                              target="_blank"
                            >
                              Cairn India Ltd
                            </a>
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>3.</th>
                          <td>
                            <b>Shri Diwakar Singhal</b>
                            <br />
                            <a
                              href="http://www.genpact.com/about-us/regions/asia/india"
                              target="_blank"
                            >
                              Genpact India
                            </a>
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>4.</th>
                          <td>
                            <b>Shri Rohit Saboo</b>
                            <br />
                            <a
                              href="https://www.ckbirlagroup.com/nbc-bearings.php"
                              target="_blank"
                            >
                              National Engineering Industries Ltd
                            </a>
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>5.</th>
                          <td>
                            <b>Shri Dinesh Nandwana</b>
                            <br />
                            <a href="http://www.vakrangee.in/" target="_blank">
                              Vakrangee Softwares Ltd
                            </a>
                          </td>
                          <td>Member</td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h4>
                    <b>Minutes of Meeting (MoM) of Executive Committee</b>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-5 mb-5">
                  <Table borderless>
                    <h6>
                      <thead>
                        <tr>
                          <th style={{ width: "808px" }}>
                            Minutes of Meeting (MoM)
                          </th>
                          <th style={{ width: "202px" }}>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            To download the MoM of 9th Executive Committee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/MoM%209EC.pdf"
                              target="_blank"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            To download the MoM of 8th Executive Committee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/executivecomitee/8th%20EC%20MOM.pdf"
                              target="_blank"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            To download the MoM of 7th Executive Committee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/executivecomitee/7th%20EC%20MOM.pdf"
                              target="_blank"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            To download the MoM of 6th Executive Committee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/executivecomitee/6th%20EC%20MOM.pdf"
                              target="_blank"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            To download the MoM of 5th Executive Committee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/executivecomitee/5th%20EC%20MOM.pdf"
                              target="_blank"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            To download the MoM of 4th Executive Committee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/executivecomitee/4th%20EC%20MOM.pdf"
                              target="_blank"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            To download the MoM of 3rd Executive Committee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/executivecomitee/3rd%20EC%20MOM.pdf"
                              target="_blank"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            To download the MoM of 2nd Executive Committee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/executivecomitee/2nd%20EC%20MOM.pdf"
                              target="_blank"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            To download the MoM of 1st Executive Committee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/executivecomitee/1st%20EC%20MOM.pdf"
                              target="_blank"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default ExecutiveCommittee;
