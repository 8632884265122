import React from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import Alert from 'react-bootstrap/Alert';

class Curriculam extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Curriculam - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Curriculam Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Curriculam, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Curriculam in IIIT Kota,Curriculam of IIIT Kota,IIIT Kota,Curriculam,Best Curriculam,Computer Science and Engineering
             Curriculam ,
             Electronics and Communication Engineering Curriculam"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Curriculum</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <h6>
                    The list of all Subjects offered by IIIT-Kota under
                    difference semester is given below. New courses might be
                    added to these list as and when they are approved.
                  </h6>
                </Col>
                <Col lg={12}>
                  <h6>
                    <b>
                      Click below to display cources from a specific discipline.
                    </b>
                  </h6>
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col lg={12}>
                  <h4>
                    <b>UG Curriculum</b>
                  </h4>
                </Col>
              </Row>

              <Row className="row-grid text-center justify-content-center mt-5">
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/IIITKOTA%20UG%20CSE%20NEW%20CURRICULUM.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/IIITKOTA%20UG%20CSE%20NEW%20CURRICULUM.pdf">
                          Computer Science and Engineering 
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/curriculum/New%20ECE%20Curriculum%202021%20Onwards.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/curriculum/New%20ECE%20Curriculum%202021%20Onwards.pdf">
                          Electronics and Communication Engineering 
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>

              {/*
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        
                        <a href="">
                        Department of Artificial Intelligence and Data Engineering
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
                */}
              </Row>
              <br />
              <br />
              {/* <Row className="row-grid text-center justify-content-center mt-5">
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/Updated%20CSE_Old%20scheme.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/Updated%20CSE_Old%20scheme.pdf">
                          Computer Science and Engineering (4th year)
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/ece_curriculam_old.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/ece_curriculam_old.pdf">
                          Electronics and Communication Engineering (4th year)
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
              <br />
              <br />
              <Row>
                <Col lg={12}>
                  <h4>
                    <b>PG Curriculum</b>
                  </h4>
                </Col>
              </Row>
              <Row className="row-grid text-center justify-content-center mt-5">
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://drive.google.com/file/d/1t-NMvVBgeVb3TyiHRoAzjlxVFK2Hq78j/view?usp=sharing"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://drive.google.com/file/d/1t-NMvVBgeVb3TyiHRoAzjlxVFK2Hq78j/view?usp=sharing">
                          Computer Science and Engineering (Full Time)
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/curriculum/MTechCSE_CurriculumAndSyllabus_FullTime.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/curriculum/MTechCSE_CurriculumAndSyllabus_PartTime.pdf">
                          Computer Science and Engineering (Part Time)
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://drive.google.com/file/d/1FQ5Gn40e0Q5sK2AMdfo2aVyYdj5zYGGP/view?usp=sharing"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://drive.google.com/file/d/1FQ5Gn40e0Q5sK2AMdfo2aVyYdj5zYGGP/view?usp=sharing">
                          Electronics and Communication Engineering
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>

              {/* <Row>
                <Col lg={12} className="mt-5">
                  <h6 className="text-justify"></h6>
                </Col>
              </Row> */}
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Curriculam;
