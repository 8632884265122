import React, { useEffect } from "react";
import { object } from "prop-types";
import Helmet from "react-helmet";

const Profile = (props) => {
  const { profileData } = props;

  const getSocialLink = () => {
    return (
      profileData.profiles &&
      profileData.profiles.map((ele) => (
        <li title={ele.username}>
          {" "}
          <a
            className={`fab fa-${ele.network.toLowerCase()} fa-2x`}
            href={ele.url}
          >
            <span className="sr-only">{ele.network}</span>
          </a>{" "}
          &nbsp; &nbsp;
        </li>
      ))
    );
  };
  useEffect(() => {
    document.title = profileData?.name;
  }, []);
  return (
    <div className="my-profile">
      <Helmet>
        <title>
          {profileData?.name +
            " - Indian Institute of Information Technology, Kota"}
        </title>
        <meta
          name="description"
          content={
            profileData?.name +
            " - Indian Institute of Information Technology, Kota"
          }
        />
        <meta
          name="keywords"
          content={
            profileData?.name +
            " IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          }
        />
        <meta name="keywords" content={profileData?.metadata} />
      </Helmet>
      <div className="profileImg">
        {profileData.picture != "" && profileData.picture != undefined && (
          <img
            role="presentation"
            className="img-circle center-block"
            src={profileData.picture}
            width="200"
            alt="profile pic"
          />
        )}
      </div>
      <h1 className="text-center h1">{profileData.name}</h1>
      <h2 className="text-center h2">{profileData.label}</h2>
      <div className="divider"></div>
      <ul className="ul contact-links text-center">
        {profileData.location != "" && profileData.location != undefined && (
          <li>
            <i className="fa fa-map-marker"></i>
            {profileData.location?.city}, {profileData.location?.region},{" "}
            {profileData.location?.countryCode}
          </li>
        )}
        {profileData.phone != "" && profileData.phone != undefined && (
          <li>
            <i className="fa fa-phone"></i>
            {profileData.phone}
          </li>
        )}
        {profileData.email != "" && profileData.email != undefined && (
          <li>
            <i className="fa fa-envelope"></i>
            <a href={`mailto:${profileData.email}`}>{profileData.email}</a>
          </li>
        )}
        {profileData.website != undefined && (
          <li>
            <i className="fa fa-link"></i>
            <a href={profileData.website}>{profileData.website}</a>
          </li>
        )}
      </ul>
      <div className="divider"></div>
      <ul className="ul profileLinks text-center">{getSocialLink()}</ul>
      <div className="divider"></div>
    </div>
  );
};

Profile.propTypes = {
  profileData: object.isRequired,
};

export default Profile;
