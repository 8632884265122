import React from "react";
import "assets/css/style.css";
import { Button, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Chairperson extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      isEnglish: true,
      readMore: false,
    };
  }

  handleLanguageChange = () => {
    this.setState((prevState) => ({
      isEnglish: !prevState.isEnglish,
    }));
  };
  handleReadmore = () => {
    this.setState((prevState) => ({
      readMore: !prevState.readMore,
    }));
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
          Chairperson - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Chairperson - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Chairperson iiit kota, Prof. A. P. S. Rathore, director mnit jaipur, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
          <meta name="author" content="Prof. A. P. S. Rathore" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Chairperson</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={4} xs={12} className="mb-5">
                  <img
                    alt="/"
                    className="director-img"
                    src={require("assets/img/people/chairman.png")}
                  ></img>

                  <div className="mt-3">
                    {/* <h6>Phone : +91 141 2529087</h6> */}
                  </div>
                  <div className="mt-3">
                    <h2>
                    Chairperson, IIIT Kota
                      <br />
                      {/* Prabha Bhawan, MNIT Campus, JLN Marg, Jaipur-302017 */}
                    </h2>
                    <span style={{ fontSize: "15px" }}>
                      PVSM UYSM AVSM SM VSM (Retd)
                    </span>
                    <br />
                    <span style={{ fontSize: "15px" }}>
                      Director General, Indian Space Association (former DGMO,
                      MS & GOC 15 Corps)
                    </span>
                  </div>
                  <div className="d-flex flex-grow-1  d-lg-none">
                    {/* <div className="d-flex flex-column row-gap-3">
                      <div>
                        <span className="btn-inner--icon mr-1">
                          <i class="fa fa-eye"></i>
                        </span>
                        <a href="https://iitr.ac.in/Departments/Electrical%20Engineering%20Department/People/Faculty/100083.html">
                          View Detailed Profile @ IITR
                        </a>
                      </div>
                      <div>
                        <span className="btn-inner--icon mr-1">
                          <i class="fa fa-eye"></i>
                        </span>
                        <a href="https://mnit.ac.in/admin/director">
                          View Detailed Profile @ MNIT
                        </a>
                      </div>
                    </div> */}
                  </div>
                  <div className="mt-3 ml-7">
                    {/* <Button
                      className="btn-icon btn-3 theme-color-kota-bg"
                      type="button"
                      href="mailto:director@iiitkota.ac.in"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fa fa-envelope" />
                      </span>
                      <span className="btn-inner--text">E-mail</span>
                    </Button> */}
                    {/* <Button
                      className="btn-icon btn-3 theme-color-kota-bg"
                      type="button"
                      href="https://www.linkedin.com/in/udaykumar-r-yaragatti-559a1514/"
                      target="_blank"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fab fa-linkedin" />
                      </span>
                      <span className="btn-inner--text">LinkedIn</span>
                    </Button> */}
                  </div>
                </Col>
                <Col lg={8} xs={12}>
                  <div className="pb-4 d-flex jusitfy-content-between">
                    <div>
                      <h2 className="display-3 mb-0">
                        <b>Lt Gen AK Bhatt</b>
                      </h2>
                      <h5>Chairperson, IIIT Kota</h5>
                    </div>
                    {/* <div className="d-lg-flex flex-grow-1 justify-content-end d-none ">
                      <div className="d-flex flex-column row-gap-3">
                        <div>
                          <span className="btn-inner--icon mr-1">
                            <i class="fa fa-eye"></i>
                          </span>
                          <a href="https://iitr.ac.in/Departments/Electrical%20Engineering%20Department/People/Faculty/100083.html">
                            View Detailed Profile @ IITR
                          </a>
                        </div>
                        <div>
                          <span className="btn-inner--icon mr-1">
                            <i class="fa fa-eye"></i>
                          </span>
                          <a href="https://mnit.ac.in/admin/director">
                            View Detailed Profile @ MNIT
                          </a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="text-justify director-content">
                    <h5>
                      Our focus is to continuously improve our performance
                      inspiring excellence and to grow into one of the best
                      institutions for engineering education in the country.
                    </h5>
                  </div>
                  <hr /> */}
                  {/* <div className="text-justify mb-6">
                    <h5 className="mb-4">
                      <b>The Brief Profile is as : </b>
                    </h5>
                    <ul>
                      <li>
                        <h6>
                          <b>PVSM UYSM AVSM SM VSM (Retd)</b>
                        </h6>
                      </li>
                      <li>
                        <h6>
                          <b>
                            Director General, Indian Space Association (former
                            DGMO, MS & GOC 15 Corps)
                          </b>
                        </h6>
                      </li> */}
                  {/* <li>
                        <h6>
                          Chairman, North Western Regional Committee{" "}
                          <b>(NWRC)</b> Chandigarh, AICTE{" "}
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Chairman, Central Seat Allotment Board{" "}
                          <b>(CSAB-17 & 2018)</b>
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Member - Joint Seat Allocation Authority{" "}
                          <b>(JoSAA)</b> 2019{" "}
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Chairperson - External committee to examine and
                          finalize the revised DPR of NITs
                        </h6>
                      </li>
                      <li>
                        <h6>Member - Finance committee IIT Jammu</h6>
                      </li>
                      <li>
                        <h6>
                          Member- General Body of National Testing Agency{" "}
                          <b>(NTA)</b>{" "}
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Member - Committee constituted for implementation and
                          support of ERP system in NITs
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Member- Central Advisory Board of Education{" "}
                          <b>(CABE)</b>, New Delhi
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Member - Translational Health Science and Technology
                          Institute <b>(THSTI)</b>
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Member of Governing Council of PGP College of
                          Engineering and Technology, Namakkal, Tamilnadu
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Board member - JN Govt. Engineering College
                          Sundarnagar
                        </h6>
                      </li>
                      <li>
                        <h6>Member – Institute of Eminence (IoE)</h6>
                      </li> */}
                  {/* </ul>
                  </div> */}
                  <hr />
                  <div className="text-justify mb-6">
                    <div className="d-flex  mb-4">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h5>
                          <b>Biography</b>
                        </h5>
                        <div className="d-flex" style={{ columnGap: 5 }}>
                          <div
                            onClick={this.handleLanguageChange}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              borderRadius: 4,
                              border: "solid 1px black",
                              padding: "3px 5px",
                              cursor: "pointer",
                              backgroundColor: this.state.isEnglish
                                ? "#0E407C"
                                : "inherit",
                              color: this.state.isEnglish
                                ? "#ffffff"
                                : "inherit",
                            }}
                          >
                            <span className=" mt-1 mr-1">
                              <i
                                class="fa fa-language"
                                style={{ fontSize: 20 }}
                              ></i>
                            </span>
                            English
                          </div>
                          <div
                            onClick={this.handleLanguageChange}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              borderRadius: 4,
                              border: "solid 1px black",
                              padding: "3px 5px",
                              cursor: "pointer",
                              backgroundColor: !this.state.isEnglish
                                ? "#0E407C"
                                : "inherit",
                              color: !this.state.isEnglish
                                ? "#ffffff"
                                : "inherit",
                            }}
                          >
                            <span className=" mt-1 mr-1">
                              <i
                                class="fa fa-language"
                                style={{ fontSize: 20 }}
                              ></i>
                            </span>
                            Hindi
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6>
                      {this.state.isEnglish ? (
                        this.state.readMore ? (
                          <div>
                            <strong>
                              Lt Gen AK Bhatt (Retd) is currently the Director
                              General of the Indian Space Association (ISpA), an
                              apex industry body created to be the collective
                              voice of the Indian Space industry. He has been
                              the first Director General of the Indian Space
                              Association (ISpA).
                            </strong>
                            <br />
                            General Bhatt has held apex leadership and
                            management positions in the Indian Army at the Army
                            Headquarters in the Indian Defense services for over
                            38 years. Among his many appointments, he has been
                            Principal Staff Officer (PSO) to the Chief of Army
                            Staff twice, while serving in the capacity of
                            Military Secretary, responsible for HR management in
                            the Army as the Director General Military Operations
                            wherein he was responsible for operational of the
                            entire Indian Army. He was DGMO during the critical
                            period of Doklam faceoff with Chinese in 2017.
                            <br />
                            General Bhatt has held critical operational
                            appointments of the Corps Commander of Prestigious
                            Chinar Corps in Srinagar in 2018 and General Office
                            Commanding Division in Assam. Both the assignments
                            involved intimate interactions with multiple
                            agencies including Central and State Govts, members
                            of civil society and academia. His ability to
                            synergize and implement challenging decisions,
                            taking along all stake holders together has been his
                            forte. General Bhatt has spearheaded several
                            projects and has demonstrated great ability in
                            building collaborative consensus while providing
                            mentorship.
                            <br />
                            He has headed numerous studies aimed at developing
                            implementable solutions to complex challenges and
                            has unparalleled insight when it comes to the
                            functioning of the Ministry of Defense, Department
                            of Military Affairs, State Govt and Civil
                            Administration. General Bhatt has an eye for astute
                            quality, an unerring ability to ensure everyone
                            under his charge complies to laws, rules and
                            regulations and has built many a top-quality team of
                            professionals that demonstrate this. Amongst his
                            many military assignments is his command of a
                            multinational unit in a UN mission in the Lebanon –
                            Israel border and tenure as instructor in Commando
                            Wing.
                            <br />
                            During a career spanning four decades, General Bhatt
                            has been the recipient of several awards from the
                            Government of India. The most recent include the
                            Param Vishisht Seva Medal (2021) and other
                            prestigious recognitions such as Uttam Yudh Seva
                            Medal (2019), Ati Vishist Seva Medal (2015), Sena
                            Medal (2014), Vishist Seva Medal (2012), Chief of
                            Army Staff Commendation Card (2008) for his
                            dedicated service to the nation.
                            <br />
                            General Bhatt is a graduate of Army Staff College in
                            UK, he has a Masters in Management Studies from
                            Osmania University and an M.Phill in Defense &
                            Strategic Studies from the prestigious Madras
                            University in Chennai. He has done his early
                            Schooling from Hampton Court School and St George’s
                            College in Mussoorie.
                            <br />
                            On 26th August 2023, The President of India has
                            appointed him as the Chairperson of Board of Governors
                            of IIIT Kota for a period of three years. As
                            Director General of Indian Space Association (ISpA)
                            he has been working relentlessly to create and
                            energize the private space ecosystem in India and
                            has been at the forefront as a single voice of the
                            entire Space Sector.
                            <br />
                          </div>
                        ) : (
                          <div>
                            <strong>
                              Lt Gen AK Bhatt (Retd) is currently the Director
                              General of the Indian Space Association (ISpA), an
                              apex industry body created to be the collective
                              voice of the Indian Space industry. He has been
                              the first Director General of the Indian Space
                              Association (ISpA).
                            </strong>
                            <br />
                            General Bhatt has held apex leadership and
                            management positions in the Indian Army at the Army
                            Headquarters in the Indian Defense services for over
                            38 years. Among his many appointments, he has been
                            Principal Staff Officer (PSO) to the Chief of Army
                            Staff twice, while serving in the capacity of
                            Military Secretary, responsible for HR management in
                            the Army as the Director General Military Operations
                            wherein he was responsible for operational of the
                            entire Indian Army. He was DGMO during the critical
                            period of Doklam faceoff with Chinese in 2017.
                            <br />
                            {/* General Bhatt has held critical operational
                            appointments of the Corps Commander of Prestigious
                            Chinar Corps in Srinagar in 2018 and General Office
                            Commanding Division in Assam. Both the assignments
                            involved intimate interactions with multiple
                            agencies including Central and State Govts, members
                            of civil society and academia. His ability to
                            synergize and implement challenging decisions,
                            taking along all stake holders together has been his
                            forte. General Bhatt has spearheaded several
                            projects and has demonstrated great ability in
                            building collaborative consensus while providing
                            mentorship. <br />
                            He has headed numerous studies aimed at developing
                            implementable solutions to complex challenges and
                            has unparalleled insight when it comes to the
                            functioning of the Ministry of Defense, Department
                            of Military Affairs, State Govt and Civil
                            Administration. General Bhatt has an eye for astute
                            quality, an unerring ability to ensure everyone
                            under his charge complies to laws, rules and
                            regulations and has built many a top-quality team of
                            professionals that demonstrate this. Amongst his
                            many military assignments is his command of a
                            multinational unit in a UN mission in the Lebanon –
                            Israel border and tenure as instructor in Commando
                            Wing. */}
                          </div>
                        )
                      ) : this.state.readMore ? (
                        <div>
                          <strong>
                            लेफ्टिनेंट जनरल एके भट्ट (सेवानिवृत्त) वर्तमान में
                            भारतीय अंतरिक्ष संघ (आईएसपीए) के महानिदेशक हैं, जो
                            एक शीर्ष उद्योग निकाय है जिसे भारतीय अंतरिक्ष उद्योग
                            की सामूहिक आवाज बनने के लिए बनाया गया है। वह भारतीय
                            अंतरिक्ष संघ (ISpA) के पहले महानिदेशक रहे हैं।
                          </strong>
                          <br />
                          जनरल भट्ट ने 38 वर्षों से अधिक समय तक भारतीय सेना
                          मुख्यालय में भारतीय रक्षा सेवाओं में शीर्ष नेतृत्व और
                          प्रबंधन पदों पर कार्य किया है। अपनी कई नियुक्तियों में
                          से, वह दो बार सेना प्रमुख के प्रधान कर्मचारी अधिकारी
                          (पीएसओ) रहे हैं, जबकि सैन्य सचिव की क्षमता में सेवा
                          करते हुए, सेना में महानिदेशक सैन्य संचालन के रूप में
                          मानव संसाधन प्रबंधन के लिए जिम्मेदार थे, जिसमें वह
                          संचालन के लिए जिम्मेदार थे। पूरी भारतीय सेना का. वह
                          2017 में चीनियों के साथ डोकलाम टकराव की महत्वपूर्ण
                          अवधि के दौरान डीजीएमओ थे।
                          <br />
                          जनरल भट्ट ने 2018 में श्रीनगर में प्रतिष्ठित चिनार कोर
                          के कोर कमांडर और असम में जनरल ऑफिस कमांडिंग डिवीजन की
                          महत्वपूर्ण परिचालन नियुक्तियों पर काम किया है। दोनों
                          कार्यों में केंद्र और राज्य सरकारों, नागरिक समाज के
                          सदस्यों और शिक्षाविदों सहित कई एजेंसियों के साथ घनिष्ठ
                          बातचीत शामिल थी। सभी हितधारकों को एक साथ लेकर,
                          चुनौतीपूर्ण निर्णयों को एकजुट करने और लागू करने की
                          उनकी क्षमता उनकी विशेषता रही है। जनरल भट्ट ने कई
                          परियोजनाओं का नेतृत्व किया है और मार्गदर्शन प्रदान
                          करते हुए सहयोगात्मक सहमति बनाने में महान क्षमता का
                          प्रदर्शन किया है।
                          <br />
                          उन्होंने जटिल चुनौतियों के कार्यान्वयन योग्य समाधान
                          विकसित करने के उद्देश्य से कई अध्ययनों का नेतृत्व किया
                          है और रक्षा मंत्रालय, सैन्य मामलों के विभाग, राज्य
                          सरकार और नागरिक प्रशासन के कामकाज के मामले में उनके
                          पास अद्वितीय अंतर्दृष्टि है। जनरल भट्ट के पास चतुर
                          गुणवत्ता पर नजर है, उनके प्रभार के तहत हर किसी को
                          कानूनों, नियमों और विनियमों का अनुपालन सुनिश्चित करने
                          की एक अचूक क्षमता है और उन्होंने पेशेवरों की कई उच्च
                          गुणवत्ता वाली टीम बनाई है जो इसे प्रदर्शित करती है।
                          उनके कई सैन्य कार्यों में लेबनान-इज़राइल सीमा पर
                          संयुक्त राष्ट्र मिशन में एक बहुराष्ट्रीय इकाई की कमान
                          और कमांडो विंग में प्रशिक्षक के रूप में कार्यकाल शामिल
                          है।
                          <br />
                          चार दशकों के करियर के दौरान, जनरल भट्ट भारत सरकार से
                          कई पुरस्कारों के प्राप्तकर्ता रहे हैं। सबसे हालिया में
                          परम विशिष्ट सेवा पदक (2021) और अन्य प्रतिष्ठित
                          मान्यताएं जैसे उत्तम युद्ध सेवा पदक (2019), अति
                          विशिष्ट सेवा पदक (2015), सेना पदक (2014), विशिष्ट सेवा
                          पदक (2012), सेना प्रमुख शामिल हैं। राष्ट्र के प्रति
                          समर्पित सेवा के लिए स्टाफ कमेंडेशन कार्ड (2008)।
                          <br />
                          जनरल भट्ट ब्रिटेन के आर्मी स्टाफ कॉलेज से स्नातक हैं,
                          उन्होंने उस्मानिया विश्वविद्यालय से प्रबंधन अध्ययन में
                          स्नातकोत्तर और चेन्नई के प्रतिष्ठित मद्रास
                          विश्वविद्यालय से रक्षा और सामरिक अध्ययन में एम.फिल
                          किया है। उन्होंने अपनी शुरुआती पढ़ाई मसूरी के हैम्पटन
                          कोर्ट स्कूल और सेंट जॉर्ज कॉलेज से की है। 26 अगस्त
                          2023 को, भारत के राष्ट्रपति ने उन्हें तीन साल की अवधि
                          के लिए IIIT कोटा के बोर्ड ऑफ गवर्नर्स के अध्यक्ष के
                          रूप में नियुक्त किया है। भारतीय अंतरिक्ष संघ (आईएसपीए)
                          के महानिदेशक के रूप में वह भारत में निजी अंतरिक्ष
                          पारिस्थितिकी तंत्र को बनाने और सक्रिय करने के लिए
                          लगातार काम कर रहे हैं और पूरे अंतरिक्ष क्षेत्र की एक
                          आवाज के रूप में सबसे आगे रहे हैं।
                        </div>
                      ) : (
                        <div>
                          <strong>
                            लेफ्टिनेंट जनरल एके भट्ट (सेवानिवृत्त) वर्तमान में
                            भारतीय अंतरिक्ष संघ (आईएसपीए) के महानिदेशक हैं, जो
                            एक शीर्ष उद्योग निकाय है जिसे भारतीय अंतरिक्ष उद्योग
                            की सामूहिक आवाज बनने के लिए बनाया गया है। वह भारतीय
                            अंतरिक्ष संघ (ISpA) के पहले महानिदेशक रहे हैं।
                          </strong>
                          <br />
                          जनरल भट्ट ने 38 वर्षों से अधिक समय तक भारतीय सेना
                          मुख्यालय में भारतीय रक्षा सेवाओं में शीर्ष नेतृत्व और
                          प्रबंधन पदों पर कार्य किया है। अपनी कई नियुक्तियों में
                          से, वह दो बार सेना प्रमुख के प्रधान कर्मचारी अधिकारी
                          (पीएसओ) रहे हैं, जबकि सैन्य सचिव की क्षमता में सेवा
                          करते हुए, सेना में महानिदेशक सैन्य संचालन के रूप में
                          मानव संसाधन प्रबंधन के लिए जिम्मेदार थे, जिसमें वह
                          संचालन के लिए जिम्मेदार थे। पूरी भारतीय सेना का. वह
                          2017 में चीनियों के साथ डोकलाम टकराव की महत्वपूर्ण
                          अवधि के दौरान डीजीएमओ थे।
                          <br />
                          {/* जनरल भट्ट ने 2018 में श्रीनगर में प्रतिष्ठित चिनार कोर
                          के कोर कमांडर और असम में जनरल ऑफिस कमांडिंग डिवीजन की
                          महत्वपूर्ण परिचालन नियुक्तियों पर काम किया है। दोनों
                          कार्यों में केंद्र और राज्य सरकारों, नागरिक समाज के
                          सदस्यों और शिक्षाविदों सहित कई एजेंसियों के साथ घनिष्ठ
                          बातचीत शामिल थी। सभी हितधारकों को एक साथ लेकर,
                          चुनौतीपूर्ण निर्णयों को एकजुट करने और लागू करने की
                          उनकी क्षमता उनकी विशेषता रही है। जनरल भट्ट ने कई
                          परियोजनाओं का नेतृत्व किया है और मार्गदर्शन प्रदान
                          करते हुए सहयोगात्मक सहमति बनाने में महान क्षमता का
                          प्रदर्शन किया है।
                          <br />
                          उन्होंने जटिल चुनौतियों के कार्यान्वयन योग्य समाधान
                          विकसित करने के उद्देश्य से कई अध्ययनों का नेतृत्व किया
                          है और रक्षा मंत्रालय, सैन्य मामलों के विभाग, राज्य
                          सरकार और नागरिक प्रशासन के कामकाज के मामले में उनके
                          पास अद्वितीय अंतर्दृष्टि है। जनरल भट्ट के पास चतुर
                          गुणवत्ता पर नजर है, उनके प्रभार के तहत हर किसी को
                          कानूनों, नियमों और विनियमों का अनुपालन सुनिश्चित करने
                          की एक अचूक क्षमता है और उन्होंने पेशेवरों की कई उच्च
                          गुणवत्ता वाली टीम बनाई है जो इसे प्रदर्शित करती है।
                          उनके कई सैन्य कार्यों में लेबनान-इज़राइल सीमा पर
                          संयुक्त राष्ट्र मिशन में एक बहुराष्ट्रीय इकाई की कमान
                          और कमांडो विंग में प्रशिक्षक के रूप में कार्यकाल शामिल
                          है।
                          <br /> */}
                        </div>
                      )}
                    </h6>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        onClick={this.handleReadmore}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 4,
                          border: "solid 1px black",
                          padding: "3px 7px",
                          cursor: "pointer",
                          backgroundColor: "#0E407C",
                          color: "#ffffff",
                        }}
                      >
                        {this.state.readMore
                          ? this.state.isEnglish
                            ? "View less"
                            : "कम देखें"
                          : this.state.isEnglish
                            ? "View Detailed Profile"
                            : "विस्तृत प्रोफाइल देखे"}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Chairperson;
